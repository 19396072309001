// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.checkListItem_Ikk0- {\n  margin-bottom: 8px;\n  display: grid;\n  grid-column-gap: 8px;\n  -moz-column-gap: 8px;\n       column-gap: 8px;\n  font-size: 1rem;\n  line-height: 1.25rem;\n  grid-template-columns: min-content auto\n}\n.checkListItem_Ikk0- > * {\n  align-self: center\n}\n.checkListItem_Ikk0-.desktop-view {\n  color: rgba(0,0,0,0.7)\n}\n@media (min-width: 992px) {\n.checkListItem_Ikk0-.desktop-view {\n    font-size: 1.25rem\n}\n.checkListItem_Ikk0-.desktop-view svg {\n    height: 32px;\n    width: 32px\n}\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkListItem": "checkListItem_Ikk0-"
};
module.exports = ___CSS_LOADER_EXPORT___;
