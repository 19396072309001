
import Vue from "vue"
import { getScreenWidth } from "~/scripts/useful-functions"

export default Vue.extend({
  name: "NavMenu",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    showingAllServices: {
      type: Boolean,
      default: false
    },
    helpCentre: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      screenWidth: null as null | number
    }
  },
  computed: {
    navDimensions() {
      if (this.showingAllServices) {
        return "w-[600px] h-[520px]"
      } else if (this.helpCentre) {
        return "w-[410px] h-[560px]"
      } else {
        return "w-[350px] h-[490px]"
      }
    }
  },
  watch: {
    show() {
      const chatWidget = document.getElementById("chat-widget-container")
      const responseIqWidget = document.getElementById("responseiq-app-container")

      if (this.screenWidth && this.screenWidth < 768) {
        if (chatWidget) {
          chatWidget.style.display = this.show ? "none" : "block"
        }
        if (responseIqWidget) {
          responseIqWidget.style.display = this.show ? "none" : "block"
        }
        if (this.show) {
          this.preventBodyScroll()
        } else {
          this.restoreBodyScroll()
        }
      }
    }
  },
  mounted() {
    document.addEventListener("keydown", this.escapeClosePopup)
    this.screenWidth = getScreenWidth()
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.escapeClosePopup)
  },
  methods: {
    escapeClosePopup(e) {
      if (e.key === "Escape" && this.show) {
        this.$emit("hide", false)
      }
    },
    preventBodyScroll() {
      // smooth scroll to top before showing menu
      window.scrollTo({ top: 0, behavior: "smooth" })
      // small delay to prevent jarring transition
      setTimeout(() => {
        document.body.style.overflow = "hidden"
        document.body.style.position = "fixed"
      }, 300)
    },

    restoreBodyScroll() {
      document.body.style.overflow = ""
      document.body.style.position = ""
    }
  }
})
