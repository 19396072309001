
import { getBlockAttributes } from "~/utils/block"
import { VALUE_ADD_REVERSE } from "~/constants/blocks"

export default {
  name: "ValueAddSection",

  props: {
    block: {
      type: Object,
      required: true
    },
    lazy: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    getBlockAttributes(block) {
      return getBlockAttributes(block)
    },
    getReverseClass(block) {
      return getBlockAttributes(block).class.includes(VALUE_ADD_REVERSE) ? "value-add-reverse" : ""
    }
  }
}
