// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loginForm_VnsMM{--tw-bg-opacity:1;background-color:rgba(41, 65, 105, 1);background-color:rgba(41, 65, 105, var(--tw-bg-opacity));--tw-text-opacity:1;color:rgba(255, 255, 255, 1);color:rgba(255, 255, 255, var(--tw-text-opacity));font-weight:600}.formContainer_oGqWd{position:relative;padding-left:15px;padding-right:15px;padding-top:25px;padding-bottom:25px}.closeBtn_N9X43{position:absolute;top:6px;right:18px;color:#fff;font-size:26px;-webkit-text-decoration:none;text-decoration:none}.fieldSet_v8egs{display:block}@media (min-width: 992px){.fieldSet_v8egs{display:flex;-moz-column-gap:10px;column-gap:10px}}.formTitle_VqLcn{font-size:28px;margin:24px 20px 0 0}.formField_uwBWj{font-size:16px}.formLabel_qcJDd{display:block;font-size:14px;margin-top:15px;margin-bottom:5px}@media (min-width: 992px){.formLabel_qcJDd{margin-top:0px}}.formInput_rruKQ{display:block;width:100%;height:42px;padding:6px 12px;font-size:16px;line-height:1.42857143;color:#555;background-color:#fff;background-image:none;border:1px solid #ccc;border-radius:4px;box-shadow:inset 0 1px 1px rgba(0,0,0,.075);transition:border-color ease-in-out .15s, box-shadow ease-in-out .15s}@media (min-width: 992px){.formInput_rruKQ{height:36px}}.formBtn_GenqB{display:inline-block;margin-bottom:0;font-weight:normal;text-align:center;vertical-align:middle;touch-action:manipulation;cursor:pointer;background-image:none;border:1px solid rgba(0,0,0,0);white-space:nowrap;padding:6px 12px;font-size:16px;line-height:1.42857143;border-radius:4px;-webkit-appearance:button;-moz-appearance:button;appearance:button;-webkit-user-select:none;-moz-user-select:none;user-select:none;color:#fff;background-color:#41a5dd;border-color:#2e6da4;margin-top:27px}.formLink_YLkMs{display:block;color:#fff;font-size:14px;-webkit-text-decoration:underline;text-decoration:underline;margin-top:24px}@media (min-width: 992px){.formLink_YLkMs{margin-top:34px;margin-left:10px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginForm": "loginForm_VnsMM",
	"formContainer": "formContainer_oGqWd",
	"closeBtn": "closeBtn_N9X43",
	"fieldSet": "fieldSet_v8egs",
	"formTitle": "formTitle_VqLcn",
	"formField": "formField_uwBWj",
	"formLabel": "formLabel_qcJDd",
	"formInput": "formInput_rruKQ",
	"formBtn": "formBtn_GenqB",
	"formLink": "formLink_YLkMs"
};
module.exports = ___CSS_LOADER_EXPORT___;
