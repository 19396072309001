// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.headerCards_yMrfg {\n  display: grid;\n  grid-gap: 16px;\n  gap: 16px;\n}\n@media (min-width: 768px) {\n.headerCards_yMrfg {\n    gap: 24px;\n}\n}\n.headerCards_yMrfg {\n  display: grid;\n  grid-auto-rows: min-content;\n  grid-template-rows: auto auto; /* Two rows */\n  grid-template-columns: repeat(6, 1fr);\n} /* First row with two equal columns */\n\n  /* Explicitly place the first two children in the first row */\n.headerCards_yMrfg > :nth-child(1) {\n    grid-column: 1 / 4;\n}\n.headerCards_yMrfg > :nth-child(2) {\n    grid-column: 4 / 7;\n}\n.headerCards_yMrfg > :nth-child(3) {\n    grid-column: 1 / 3;\n}\n.headerCards_yMrfg > :nth-child(4) {\n    grid-column: 3 / 5;\n}\n.headerCards_yMrfg > :nth-child(5) {\n    grid-column: 5 / 7;\n}\n.headerCards_yMrfg .loadingPlaceholder_5kVTt {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n    aspect-ratio: 4 / 3;\n}\n\n  /* Fixes layout shift prior to images loading */\n.headerCards_yMrfg .loadingPlaceholder_5kVTt img {\n  height: auto;\n  width: 100%;\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerCards": "headerCards_yMrfg",
	"loadingPlaceholder": "loadingPlaceholder_5kVTt"
};
module.exports = ___CSS_LOADER_EXPORT___;
