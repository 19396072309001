import { Vue, Component, Prop, Getter } from "nuxt-property-decorator"
import { GET_PHONE_NUMBER_VALUE } from "~/store/phone-number/getters"

@Component
export default class Header extends Vue {
  showLoginForm: boolean = false
  showPanelMenu: boolean = false
  showMenuSection: boolean | string = false
  isOpenMyAnyVan: boolean = false

  @Prop({ type: Boolean, default: false }) plainHeader: boolean
  @Prop({ type: Boolean, default: false }) isRebrand: boolean
  @Getter(GET_PHONE_NUMBER_VALUE, { namespace: "phone-number" })
  getPhoneNumberValue: PhoneNumberPair
}
