
import diff from "deep-diff"
import { getSizeClasses, getSelectSizeClasses } from "~/scripts/css-helper"

/**
 * Create a select like input (not real `select` element).
 * ```
 * let options = [
 *   {
 *     value: 1,
 *     name: "First option"
 *   },
 *   {
 *     value: 2,
 *     name: "Second option"
 *   },
 *   {
 *     value: 3,
 *     name: "Third option"
 *   }
 * ]
 * ```
 * `Sel(:options="options" option-value="value" option-text="name") `
 */
export default {
  name: "Sel", // eslint-disable-line vue/multi-word-component-names

  filters: {
    truncate(text, length, suffix) {
      if (length === "") {
        return text
      }

      length = parseInt(length)
      if (length && text.length > length) {
        return text.substring(0, length) + suffix
      }

      return text
    }
  },

  props: {
    label: {
      type: String,
      default: ""
    },
    labelPosition: {
      type: String,
      default: "top",
      validator: value => ["top", "left", "animated", "border"].includes(value)
    },
    /** Options list */
    options: {
      type: Array,
      required: true
    },
    /**
     * Property name from an `Option` object. Set only if you want to use the property
     * value as `Sel` key, otherwise the whole object will be the key (and `v-model`’s value).
     */
    // eslint-disable-next-line vue/require-default-prop
    optionValue: {
      type: String,
      required: false
    },
    /**
     * Property name from an `Option` object. Set only if you want to display this property in the
     * `Sel` dropdown, otherwise the whole object will be printed.
     */
    // eslint-disable-next-line vue/require-default-prop
    optionText: {
      type: String,
      required: false
    },
    /** Selected option */
    // eslint-disable-next-line
    value: {
      // type: [Object, Boolean, String, Number, null],
      default: null
    },
    /** Default text if nothing selected */
    placeholder: {
      type: String,
      default: "Please Select"
    },
    /** Indicator is a caret up and down */
    carets: {
      type: Boolean,
      default: false
    },
    optionsToShow: {
      type: Number,
      default: 0
    },
    size: {
      type: String,
      default: "m",
      validator: value => ["xs", "s", "m", "l"].includes(value)
    },
    /** Error message */
    error: {
      type: String,
      default: ""
    },
    /** The selected text will be the placeholder - need in postcode search component */
    alwaysPlaceholder: {
      type: Boolean,
      default: false
    },
    /** We can remove the selected option from the dropdown */
    removeSelected: {
      type: Boolean,
      default: false
    },
    truncateLength: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    /** show classic select */
    classic: {
      type: Boolean,
      default: false
    },
    /** Icon shortcut */
    iconLeft: {
      type: Array,
      default: () => []
    },
    /** Icon shortcut */
    iconRight: {
      type: Array,
      default: () => []
    },
    /** Icon size */
    // eslint-disable-next-line vue/require-default-prop
    iconSize: {
      type: Number,
      required: false
    },
    /** Icon colour */
    // eslint-disable-next-line vue/require-default-prop
    iconColor: {
      type: String,
      required: false
    },
    border: {
      type: Boolean,
      default: true
    },
    backgroundColor: {
      type: String,
      default: ""
    },
    iconPadding: {
      type: Number,
      required: false,
      default: null
    },
    indicator: {
      type: Boolean,
      default: true
    },
    initialOpen: {
      type: Boolean,
      default: false
    },
    subMenu: {
      type: Boolean,
      default: false
    },
    optionIcon: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      isOpen: this.initialOpen,
      xPadding: null
    }
  },

  computed: {
    labelClasses() {
      return getSizeClasses(this.size)
    },

    classes() {
      return [this.$style.sel, ...getSelectSizeClasses(this.size)]
    },

    // :style="styles() ? styles().join(';') : ''")
    // Unselected options for the dropdown
    selectableOptions() {
      if (this.alwaysPlaceholder || !this.removeSelected) {
        return this.options
      }

      return this.optionValue
        ? this.options.filter(option => option[this.optionValue] !== this.value)
        : this.options.filter(option => JSON.stringify(option) !== JSON.stringify(this.value))
    },

    getSelectedText() {
      if (this.value === null || this.alwaysPlaceholder) {
        return this.placeholder
      }

      const value = this.optionValue ? this.value : this.value.value
      const valueField = this.optionValue ? this.optionValue : "value"

      if (typeof this.options.find(option => option[valueField] === value) === "undefined") {
        return this.placeholder
      }

      return this.optionText
        ? this.options.find(option => option[valueField] === value)[this.optionText]
        : this.options.find(option => option[valueField] === value).name
    },

    iconFontSize() {
      let size = 16

      if (this.iconSize) {
        size = this.iconSize
      } else {
        switch (this.size) {
          case "xs":
            size = 16
            break
          case "s":
            size = 18
            break
          case "m":
            size = 20
            break
          case "l":
            size = 24
            break
        }
      }

      return size
    },

    iconStyles() {
      const styles = []

      if (this.iconLeft.length >= 2 || this.iconRight.length >= 2) {
        styles.push(`width: ${this.xPadding}px`)
      }

      if (this.iconColor) {
        styles.push(`color: ${this.iconColor}`)
      }

      return styles
    },

    styles() {
      const styles = []

      if (!this.indicator) {
        styles.push(`padding-right: 0`)
      }

      if (this.iconLeft.length >= 2) {
        styles.push(`padding-left: ${this.xPadding}px`)
      }

      if (this.iconRight.length >= 2) {
        styles.push(`padding-right: ${this.xPadding}px`)
      }

      if (!this.border) {
        styles.push(`border: 0`)
      }

      if (this.backgroundColor) {
        styles.push(`background-color: ${this.backgroundColor}`)
      }

      return styles
    },

    optionsHeight() {
      let maxHeight = "unset"

      if (this.optionsToShow) {
        switch (this.size) {
          case "xs":
            maxHeight = this.optionsToShow * 30 + 5
            break
          case "s":
            maxHeight = this.optionsToShow * 41 + 5
            break
          case "m":
            maxHeight = this.optionsToShow * 47 + 5
            break
          case "l":
            maxHeight = this.optionsToShow * 50 + 5
            break
        }

        maxHeight += "px"
      }

      return "max-height: " + maxHeight + ";"
    },

    selectedOption: {
      get() {
        return this.value ? (this.optionValue ? this.value : JSON.stringify(this.value)) : null
      },
      set(newValue) {
        this.selectOption(newValue)
      }
    }
  },

  beforeMount() {
    if (this.value) {
      const selectedOption = this.options.find(option => {
        if (this.optionValue) {
          return option[this.optionValue] === this.value
        }
        return typeof diff(option, this.value) === "undefined"
      })
    }
  },

  mounted() {
    if (this.iconLeft.length >= 2 || this.iconRight.length >= 2) {
      if (this.iconPadding) {
        this.xPadding = this.iconPadding
      } else {
        this.xPadding = this.iconSize ? this.iconSize * 2 : this.iconFontSize * 2
      }
    }
  },

  methods: {
    // Option id
    parsedValue(option) {
      return this.optionValue ? option[this.optionValue] : JSON.stringify(option)
    },

    // Option icon
    parsedIcon(option) {
      if (!this.optionValue) {
        return ""
      }
      let icon = this.parsedValue(option)
      if (icon === "") {
        icon = "back"
      } else {
        icon = icon.toLowerCase().replace(" ", "-").replace(" ", "-")
      }

      return icon
    },

    // Option text
    parsedText(option) {
      return this.optionText ? option[this.optionText] : option
    },

    setOpen(isOpen) {
      if (isOpen && this.$props.disabled) {
        return
      }

      if (isOpen && (!this.selectableOptions || !this.selectableOptions.length)) {
        return
      }

      this.isOpen = isOpen
    },

    selectOption(option) {
      if (!this.optionValue && this.classic) {
        option = JSON.parse(option)
      }

      if (this.classic) {
        this.$emit("input", option)
      } else {
        this.$emit("input", this.optionValue ? option[this.optionValue] : option)
      }
    },

    clickOutside() {
      this.isOpen = false
    }
  }
}
