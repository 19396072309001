export const LANGUAGE_OPTIONS: OptionString[] = [
  { name: "UK", value: "en" },
  { name: "Germany", value: "de" },
  { name: "Italy", value: "it" },
  { name: "France", value: "fr" },
  { name: "Spain", value: "es" }
]

export const MAP_LOCALES = {
  en: "en_GB",
  ie: "en_IE",
  de: "de_DE",
  it: "it_IT",
  fr: "fr_FR",
  es: "es_ES"
}

export const MAP_LANGUAGE_CODES = {
  en: "EN",
  ie: "GA",
  de: "DE",
  it: "IT",
  fr: "FR",
  es: "ES"
}
