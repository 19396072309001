
import { getBlockAttribute } from "~/utils/block"

export default {
  name: "AccordionOriginal",
  props: {
    block: { required: true, type: Object },
    active: { type: Boolean, default: false },
    index: {
      type: Number,
      default: null
    }
  },
  methods: {
    setActive() {
      this.$emit("clicked", this.index)
    },
    getBlockAttribute(...args) {
      return getBlockAttribute(...args)
    }
  }
}
