
import { AINOBLOCKS_ACCORDION_FAQ_BLOCK } from "~/constants/blocks"

export default {
  name: "FaqsOriginal",

  props: {
    block: {
      type: Object, // Record<string, any>
      required: true
    }
  },

  data() {
    return {
      AINOBLOCKS_ACCORDION_FAQ_BLOCK,
      activeFaq: null
    }
  },

  methods: {
    setActiveFaq(faqIndexClicked) {
      this.activeFaq = faqIndexClicked === this.activeFaq ? null : faqIndexClicked
    }
  }
}
