
import { getAttributesWithoutStyles } from "~/utils/block"

export default {
  name: "PriceBanner",

  props: {
    block: {
      type: Object,
      required: true
    },
    lazy: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {}
  },

  computed: {},

  methods: {
    getAttributesWithoutStyles(block) {
      return getAttributesWithoutStyles(block)
    }
  }
}
