
import Vue from "vue"
import { mapGetters } from "vuex"
import { GET_INTERNAL_REVIEWS } from "~/store/internal-reviews/getters"

export default Vue.extend({
  name: "InternalReviewSection",
  props: {
    block: {
      required: true,
      type: Object as () => Block
    }
  },
  computed: {
    ...mapGetters("internal-reviews", {
      reviews: GET_INTERNAL_REVIEWS
    })
  }
})
