// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".trustPilot_rcdtd{padding-left:15px;color:#909090}.trustPilot_rcdtd h4{font-size:20px;font-weight:bold;margin-top:11px;margin-bottom:11px}.darkLogo_hBb7x,.lightLogo_hU0f5{width:100%}.rating_7-1h-{width:80%;margin:10px 0 0}@media(min-width: 768px){.rating_7-1h-{margin:10px 0}}.dark_BeMp2 .lightLogo_hU0f5{display:none}.light_eriVC{color:#fff}.light_eriVC .darkLogo_hBb7x{display:none}.light_eriVC div a{color:#41a5dd}@media (min-width: 992px){.trustPilot_rcdtd div{width:200px !important}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"trustPilot": "trustPilot_rcdtd",
	"darkLogo": "darkLogo_hBb7x",
	"lightLogo": "lightLogo_hU0f5",
	"rating": "rating_7-1h-",
	"dark": "dark_BeMp2",
	"light": "light_eriVC"
};
module.exports = ___CSS_LOADER_EXPORT___;
