
  import { createPopper } from "@popperjs/core"
  import vClickOutside from "v-click-outside"
  import Vue from "vue"

  Vue.use(vClickOutside)
  export default {
    name: "AvPopper",
    props: {
      value(value) {
        this.showTooltip = value
      },
      placement: {
        type: String,
        default: "bottom-end"
      },
      boundary: {
        type: String,
        default: "scrollParent"
      },
      openOnClick: {
        type: Boolean,
        default: true
      },
      closeOnSecondClick: {
        type: Boolean,
        default: false
      },
      extraModifiers: {
        type: Array,
        default: () => []
      },
      disabled: {
        type: Boolean,
        default: false
      },
      closeOnPopperOpen: {
        type: Boolean,
        default: false
      },
      onHover: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        showTooltip: false,
        popper: null,
        defaultModifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 8]
            }
          },
          {
            name: "preventOverflow",
            options: {
              altAxis: true
            }
          }
        ]
      }
    },
    computed: {
      vcoConfig() {
        return {
          handler: this.hide,
          events: ["dblclick", "click"],
          isActive: this.showTooltip
        }
      }
    },
    watch: {
      value: {
        handler(value) {
          this.showTooltip = value
          if (this.Popper){
            this.Popper.update()
          }
        },
        immediate: true
      },
    },
    methods: {
      show() {
        if (this.disabled) {
          return
        }
        if (this.closeOnSecondClick && this.showTooltip) {
          this.showTooltip = false
          this.$emit('input', false)
        } else if (this.openOnClick) {
          this.showTooltip = true
          this.$emit('input', true)
        }
        this.Popper.update()
      },
      hide() {
        if (this.disabled) {
          return
        }
        this.showTooltip = false
        this.$emit('input', false)
        this.$emit("click-outside")
      }
    },
    created() {
      this.modifiers = [...this.defaultModifiers, ...this.extraModifiers]
      if (this.closeOnPopperOpen) {
        document.addEventListener("popperOpen", () => {
          this.showTooltip = false
        })
      }
    },
    mounted() {
      const button = this.$refs.button
      const tooltip = this.$refs.tooltip
      tooltip.style.zIndex = "100"

      this.Popper = createPopper(button, tooltip, {
        placement: this.placement,
        modifiers: this.modifiers
      })

      document.addEventListener("keydown", e => {
        if (e.key === "Escape") {
          this.showTooltip = false
        }
      })
    },
  }
