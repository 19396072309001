
import Vue from "vue"
import "swiper/swiper-bundle.min.css"
import { Swiper } from "swiper/bundle"
import { timeAgo } from "~/scripts/useful-functions"

export interface Review {
  id: number
  name: string
  location: string
  rating: number
  review: string
  type: string
  date: string
  isOverflowing?: boolean
}

export default Vue.extend({
  name: "InternalReviewCarousel",
  props: {
    reviews: {
      required: true,
      type: Array
    }
  },
  data() {
    return {
      showFullReviewModal: false,
      reviewModalData: {} as Review,
      reviewsSwiper: {} as Swiper,
      reviewsCopy: [] as Review[]
    }
  },
  computed: {
    baseParams() {
      return {
        loop: false,
        observer: true,
        slidesPerView: 1,
        spaceBetween: 24,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        speed: 400,
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 30
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 30
          }
        },

        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        on: {
          init: () => {
            this.$nextTick(() => {
              // make slider visible after it's initialized to avoid flicker
              const swiperWrapperEl = document.querySelector(".internal-reviews-swiper")
              if (swiperWrapperEl) {
                ;(swiperWrapperEl as HTMLElement).style.height = "auto"
              }
              this.checkOverflow()
            })
          }
        }
      } as Record<string, any>
    }
  },

  created() {
    this.reviewsCopy = JSON.parse(JSON.stringify(this.reviews)) as Review[]
  },

  mounted() {
    this.reviewsSwiper = new Swiper(".internal-reviews-swiper", this.baseParams)
    window.addEventListener("resize", this.checkOverflow)
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.checkOverflow)
  },

  methods: {
    checkOverflow(): void {
      this.$nextTick(() => {
        this.reviewsCopy.forEach((review, index) => {
          const el = this.$refs[`reviewText-${index}`] as HTMLElement[]
          if (el && el[0]) {
            this.$set(review, "isOverflowing", el[0].scrollHeight > el[0].clientHeight)
          }
        })
      })
    },
    showFullReview(index) {
      this.reviewModalData = this.reviewsCopy[index]
      this.showFullReviewModal = true
    },
    timeAgo(date: string): string {
      return timeAgo(date)
    },
    createStarRating(rating) {
      let starsDiv = ""
      // starsDiv.className = "flex gap-2"
      const fullStars = Math.floor(rating)
      const hasHalfStar = rating % 1 !== 0

      // add full stars
      starsDiv +=
        `<img class='internalReviewStar' src='/content-static/icons/star-hard-yellow.svg'/>`.repeat(
          fullStars
        )

      if (hasHalfStar) {
        starsDiv += `<img class='internalReviewStar' src='/content-static/icons/half-star-hard-yellow.svg'/>`
      }

      return starsDiv
    }
  }
})
