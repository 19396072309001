
import Vue from "vue"

export default Vue.extend({
  name: "NavMenuItem",
  props: {
    url: {
      type: String,
      default: ""
    },
    image: {
      type: String,
      default: null
    },
    chevron: {
      type: Boolean,
      default: false
    },
    noyellowbar: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "l",
      validator: (value: string) => ["s", "l"].includes(value)
    }
  }
})
