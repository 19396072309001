import { Vue, Component } from 'nuxt-property-decorator'
import tailwindConfig from '~/tailwind.config.js'

@Component
export default class Breakpoints extends Vue {
  queryList: {} = {}
  listeners: {} = {}
  breakpoints: {
    xs: string
    sm: string
    md: string
    lg: string
    xl: string
    xxl: string
    rebrandLg: string
    rebrandNav: string
  } = tailwindConfig.theme.screens

  getMediaQuery(name, useMax = false) {
    const breakpoint = this.breakpoints[name]

    if (useMax) {
      const number = parseFloat(breakpoint)
      const unit = breakpoint.replace(number, '')
      const substract = unit === 'em' ? 0.0635 : 1
      return `(max-width: ${number - substract}${unit})`
    }

    return `(min-width: ${breakpoint})`
  }

  listenMedia(val) {
    Vue.set(this.queryList, val.media, val.matches)
  }

  Breakpoints_matchMedia(name, useMax = false) {
    let mq
    const mqName = this.getMediaQuery(name, useMax)

    if (typeof window !== 'undefined') {
      if (this.queryList[mqName]) {
        return this.queryList[mqName]
      } else {
        mq = window.matchMedia(mqName)
        mq.addListener(this.listenMedia)
        this.listeners[mqName] = mq
        return window.matchMedia(this.getMediaQuery(name, useMax)).matches
      }
    } else return true
  }

  beforeDestroy() {
    for (const mqName in this.listeners) {
      this.listeners[mqName].removeListener(this.listenMedia)
    }
  }
}
