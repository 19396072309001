
import { Vue, Component, Prop } from "nuxt-property-decorator"

@Component
export default class MediaTextComponent extends Vue {
  @Prop({ required: true, type: Object }) block: Record<string, any>
  @Prop({ default: true, type: Boolean }) lazy: boolean

  get imageData() {
    const imageUrl = this.block.attributes.find(attribute => attribute.name === "mediaLink")

    const imageId = this.block.attributes.find(attribute => attribute.name === "mediaId")

    return { url: imageUrl.value, id: imageId.value }
  }

  get positionClass() {
    let positionClass = ""

    this.block.attributes.forEach(attr => {
      if (attr.name !== undefined) {
        if (attr.name == "mediaPosition") {
          positionClass = "position-" + attr.value
        }
      }
    })

    return positionClass
  }
}
