import { render, staticRenderFns } from "./LocationFormExtension.vue?vue&type=template&id=46245c99&lang=pug"
import script from "./LocationFormExtension.vue?vue&type=script&lang=ts"
export * from "./LocationFormExtension.vue?vue&type=script&lang=ts"
import style0 from "./LocationFormExtension.vue?vue&type=style&index=0&id=46245c99&prod&lang=css&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoveSummary: require('/app/components/parts/MoveSummary.vue').default,ResponsiveImageSet: require('/app/components/generic/ResponsiveImageSet.vue').default})
