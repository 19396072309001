
import "@pqina/flip/dist/flip.min.css"
import "@pqina/flip/dist/tick.view.flip.css"

export default {
  name: "AnimatedFlipCounter",

  props: {
    value: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      _tickInstance: null
    }
  },

  mounted() {
    this.initializeTick()
  },

  methods: {
    initializeTick() {
      const element = this.$refs.tick
      if (element) {
        this._tickInstance = this.$tick.DOM.create(element, {
          value: Math.floor(this.value),
          format: {
            transform: value => Math.floor(value)
          },
          // definition for top level tick element
          view: {
            children: [
              // presenter object
              {
                root: "div",
                layout: "group",
                repeat: true,
                children: [
                  // presenter object
                  {
                    view: "flip"
                  }
                ]
              }
            ]
          },
          didInit: tick => {
            // use this lifecycle hook if you need
          }
        })
      } else {
        console.error("Tick element not found")
      }
    }
  },

  watch: {
    value(newValue) {
      if (this._tickInstance) {
        this._tickInstance.value = Math.floor(newValue)
      }
    }
  },

  beforeDestroy() {
    if (this._tickInstance) {
      this.$tick.DOM.destroy(this._tickInstance)
    }
  }
}
