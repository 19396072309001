
import Vue from "vue"
import { mapGetters } from "vuex"
import { GET_ITEMS, GET_ITEMS_COUNT, GET_UNIQUE_ITEMS_COUNT } from "~/store/listing/getters"
import { DECREASE_ITEM, INCREASE_ITEM } from "~/store/listing/actions"
import { getCustomItemDimensions } from "~/utils/item"
import { SnowplowService } from "~/services/public/Snowplow"
import { DESKTOP_SUMMARY_ITEMS_COUNT_TO_VIEW } from "~/constants/item"

export default Vue.extend({
  name: "MoveSummary",
  props: {
    isEditOpen: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      snowplowService: new SnowplowService()
    }
  },

  computed: {
    ...mapGetters("listing", {
      summary_getItems: GET_ITEMS,
      summary_getItemsCount: GET_ITEMS_COUNT,
      summary_getUniqueItemsCount: GET_UNIQUE_ITEMS_COUNT
    }),
    getItems(): Item[] {
      return this.summary_getItems
    },
    scrollRequired() {
      return this.summary_getUniqueItemsCount > DESKTOP_SUMMARY_ITEMS_COUNT_TO_VIEW
    }
  },

  methods: {
    async increaseQuantity(item: Item) {
      if (item.quantity >= 100) return
      await this.$store.dispatch("listing/" + INCREASE_ITEM, item)
      // @ts-ignore -- Property 'snowplowService' does not exist on type 'CombinedVueInstance'.
      this.snowplowService.trackAddSummaryItem(
        item.item_id || item.name,
        item.quantity ? item.quantity + 1 : 1
      )
    },

    async decreaseQuantity(item: Item) {
      if (item.quantity <= 0) return
      await this.$store.dispatch("listing/" + DECREASE_ITEM, item)
      // @ts-ignore -- Property 'snowplowService' does not exist on type 'CombinedVueInstance'.
      this.snowplowService.trackRemoveSummaryItem(
        item.item_id || item.name,
        item.quantity ? item.quantity - 1 : 0
      )
    },

    editItem(index) {
      this.$emit("edit-item", this.summary_getItems[index])
    },

    getCustomItemDimensions(item: Item) {
      return getCustomItemDimensions(item)
    }
  }
})
