
import { Vue } from "nuxt-property-decorator"

export default Vue.extend({
  props: {
    name: {
      type: String,
      default: "",
      required: true
    },
    classes: {
      type: String,
      default: ""
    }
  },
  methods: {
    getDynamicPath() {
      return require(`~/assets/img/header/partner_${this.$props.name}.svg`)
    }
  }
})
