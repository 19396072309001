
import { Component, Getter, Action, Prop, mixins } from "nuxt-property-decorator"
import { extend, ValidationProvider } from "vee-validate"
import { required } from "vee-validate/dist/rules"
import Blocks from "~/mixins/blocks"
import {
  SET_DELIVERY_FLOOR,
  SET_DELIVERY_PROPERTY_TYPE,
  SET_LISTING
} from "~/store/listing/actions"
import { GET_DELIVERY_FLOOR, GET_DELIVERY_PROPERTY_TYPE } from "~/store/listing/getters"
import InitialListing from "~/store/listing/state"
import { isFlat, isHouse, requiresFloor } from "~/services/public/Listing"
import {
  FLOORS,
  PROPERTY_TYPE_LIST_FLAT,
  PROPERTY_TYPE_LIST_HOUSE,
  PROPERTY_TYPE_LIST_MAIN
} from "~/constants/listing"
import {
  GET_FLOORS,
  GET_PROPERTY_TYPES,
  GET_PROPERTY_TYPES_FLAT,
  GET_PROPERTY_TYPES_HOUSE
} from "~/store/property/getters";

// Using ValidationProvider without validating anything is a workaround!
// ValidationProvider must be added as container for the main Sel items
// To allow subMenu Sel items using their own props
@Component({
  components: { ValidationProvider }
})
export default class PropertyDeliveryInput extends mixins(Blocks) {
  propertyTypesMain: any[] = PROPERTY_TYPE_LIST_MAIN
  propertyTypesHouse: any[] = PROPERTY_TYPE_LIST_HOUSE
  propertyTypesFlat: any[] = PROPERTY_TYPE_LIST_FLAT
  floors: any[] = FLOORS

  @Prop({ required: true, type: Object }) block: Record<string, any>
  @Prop({ default: false, type: Boolean }) virgin: boolean
  @Prop({ default: true, type: Boolean }) lazy: boolean
  @Prop({ default: false, type: Boolean }) validation: boolean

  @Action(SET_DELIVERY_PROPERTY_TYPE, { namespace: "listing" }) setDeliveryPropertyType: Function
  @Action(SET_DELIVERY_FLOOR, { namespace: "listing" }) setDeliveryFloor: Function
  @Action(SET_LISTING, { namespace: "listing" }) setListing: Function

  @Getter(GET_DELIVERY_FLOOR, { namespace: "listing" }) getDeliveryFloor: string | null
  @Getter(GET_PROPERTY_TYPES, { namespace: "property" }) getStorePropertyTypesMain: Function
  @Getter(GET_PROPERTY_TYPES_HOUSE, { namespace: "property" }) getStorePropertyTypesHouse: Function
  @Getter(GET_PROPERTY_TYPES_FLAT, { namespace: "property" }) getStorePropertyTypesFlat: Function
  @Getter(GET_FLOORS, { namespace: "property" }) getStoreFloors: Function
  @Getter(GET_DELIVERY_PROPERTY_TYPE, { namespace: "listing" }) getDeliveryPropertyType:
    | string
    | null

  created() {
    if (this.validation) {
      extend("required", required)
    }
    this.setListing(InitialListing())
  }

  get getPropertyTypesMain() {
    return this.getStorePropertyTypesMain || this.propertyTypesMain
  }

  get getPropertyTypesHouse() {
    return this.getStorePropertyTypesHouse || this.propertyTypesHouse
  }

  get getPropertyTypesFlat() {
    return this.getStorePropertyTypesFlat || this.propertyTypesFlat
  }

  get getFloors() {
    return this.getStoreFloors || this.floors
  }

  get attributes() {
    return this.virgin ? null : this.Blocks_getAttributes(this.block)
  }

  get deliveryPropertyType(): string | null {
    return this.getDeliveryPropertyType
  }

  set deliveryPropertyType(value) {
    this.setDeliveryPropertyType(value)
  }

  get deliveryFloor(): string | null {
    return this.getDeliveryFloor
  }

  set deliveryFloor(value) {
    this.setDeliveryFloor(value)
  }

  isHouse(propertyType) {
    return isHouse(propertyType)
  }

  isFlat(propertyType) {
    return isFlat(propertyType)
  }

  requiresFloor(propertyType) {
    return requiresFloor(propertyType)
  }
}
