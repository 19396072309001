import { render, staticRenderFns } from "./TheRebrandHeader.vue?vue&type=template&id=20432e94"
import script from "./TheRebrandHeader.vue?vue&type=script&lang=ts"
export * from "./TheRebrandHeader.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LoginForm: require('/app/components/LoginForm.vue').default,AnyVanLogo: require('/app/components/AnyVanLogo.vue').default,NavOurServices: require('/app/components/rebrand-header/NavOurServices.vue').default,NavHelpCentre: require('/app/components/rebrand-header/NavHelpCentre.vue').default,NavCallUs: require('/app/components/rebrand-header/NavCallUs.vue').default,NavLoginButton: require('/app/components/rebrand-header/NavLoginButton.vue').default})
