
import { Component, Prop, mixins, Getter } from 'nuxt-property-decorator'
import Blocks from '~/mixins/blocks'
import { GET_BREADCRUMBS } from '~/store/pages/getters'

@Component
export default class HeroComponent extends mixins(Blocks) {
  @Prop({ required: true, type: Object }) block: Record<string, any>
  @Getter(GET_BREADCRUMBS, { namespace: 'pages' }) getBreadcrumbs: Record<
    string,
    any
  >

  head() {
    return {
      script: [{ type: 'application/ld+json', json: this.getSchema() }],
    }
  }

  getSchema() {
    const entries = this.breadcrumbs
    const list: Record<string, any> = []
    entries.forEach((entry, index) => {
      const path = this.removeDomainFromUrl(entry.url)
      list.push({
        '@type': 'ListItem',
        position: index + 1,
        name: entry.text,
        item:
          this.$config.frontendHost +
          (path !== this.$config.frontendHost ? path : ''),
      })
    })

    if (!entries) {
      return null
    }

    return {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: list,
    }
  }

  get breadcrumbs() {
    if (this.block.innerBlocks[4] && this.block.innerBlocks[4].innerHtml) {
      return this.getBreadcrumbs
    }

    return []
  }

  get parentAttrs() {
    return this.removeParagraphClass(
      this.Blocks_getAttributes(this.block.innerBlocks[0])
    )
  }

  get separatorAttrs() {
    return this.removeParagraphClass(
      this.Blocks_getAttributes(this.block.innerBlocks[1])
    )
  }

  get childAttrs() {
    return this.removeParagraphClass(
      this.Blocks_getAttributes(this.block.innerBlocks[2])
    )
  }

  removeParagraphClass(attrs) {
    attrs.class = attrs.class.replace('av-paragraph ', '')
    return attrs
  }

  removeDomainFromUrl(url) {
    const path = url.match(/^https?:\/\/[A-Za-z0-9:.-]*(.*\/?)$/)
    if (path[1] === '/') {
      return this.$config.frontendHost
    }

    if (path) {
      return path[1].replace(/\/$/, '')
    }
  }
}
