
import Vue from "vue"
import { mapGetters } from "vuex"
import { GET_PHONE_NUMBER_VALUE } from "~/store/phone-number/getters"
import TrustPilotWidget from "~/components/generic/TrustPilotWidget.vue"
import { SnowplowService } from "~/services/public/Snowplow"

export default Vue.extend({
  name: "PanelCTAs",
  components: { TrustPilotWidget },
  data() {
    return {
      formSubmitted: false,
      snowplowService: null as SnowplowService | null
    }
  },
  computed: {
    ...mapGetters("phone-number", {
      getPhoneNumberValue: GET_PHONE_NUMBER_VALUE
    })
  },
  mounted() {
    this.snowplowService = new SnowplowService()
  },
  methods: {
    submitForm() {
      this.$emit("submit-form")
    },
    handleCallUsClick() {
      this.snowplowService?.trackCallUs({
        phoneNumber: this.getPhoneNumberValue.baseRaw,
        label: "panel_cta_button",
        action: "click",
        extension: this.getPhoneNumberValue.extension
      })
      window.location.href = `tel:${this.getPhoneNumberValue.base}`
    }
  }
})
