// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.comparisonTable_VV96c {\n      width: 100%;\n      border-collapse: collapse;\n      text-align: center;\n}\n.comparisonTable_VV96c tr th,\n    .comparisonTable_VV96c tr td {\n      height: 64px;\n      min-width: 72px;\n      padding: 0 16px;\n}\n.comparisonTable_VV96c tr th {\n      font-weight: 400;\n      border-radius: 16px 16px 0 0;\n}\n.comparisonTable_VV96c tr td {\n      border: 1px solid #c5c5c5;\n}\n.comparisonTable_VV96c tr:first-child th {\n      border-top: 0;\n}\n.comparisonTable_VV96c tr:last-child td {\n      border-bottom: 0;\n}\n.comparisonTable_VV96c tr td:first-child,\n    .comparisonTable_VV96c tr th:first-child {\n      width: 100%;\n      border-left: 0;\n      padding: 8px;\n}\n.comparisonTable_VV96c tr td:last-child,\n    .comparisonTable_VV96c table tr th:last-child {\n      border-right: 0;\n}\n.comparisonTable_VV96c tr th:nth-child(2),\n    .comparisonTable_VV96c tr td:nth-child(2) {\n      background-color: rgba(177, 227, 255, 0.35);\n}\n@media (min-width: 768px) {\n.comparisonTable_VV96c img {\n        width: 48px;\n        margin: auto;\n}\n.comparisonTable_VV96c tr th,\n      .comparisonTable_VV96c tr td {\n        min-width: 96px;\n}\n}\n@media (min-width: 992px) {\n.comparisonTable_VV96c img {\n        width: 104px;\n}\n.comparisonTable_VV96c tr th,\n      .comparisonTable_VV96c tr td {\n        height: 104px;\n        min-width: 184px;\n}\n.comparisonTable_VV96c tr th {\n            font-size: 1.25rem;\n}\n.comparisonTable_VV96c tr td {\n            font-size: 1.5rem;\n}\n.comparisonTable_VV96c tr td:first-child {\n            text-align: center;\n}\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"comparisonTable": "comparisonTable_VV96c"
};
module.exports = ___CSS_LOADER_EXPORT___;
