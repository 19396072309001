
import { Component, Prop, mixins } from "nuxt-property-decorator"
import Blocks from "~/mixins/blocks"
import {
  AV_FAQ,
  AV_FAQ_OLD,
  BREADCRUMBS,
  CORE_MEDIA_TEXT,
  CORE_QUERY,
  FAQS_WRAPPER,
  PAGE_TILE_NAVIGATION,
  PAGE_TILE_NAVIGATION_FULL_IMAGE,
  TRUSTPILOT_REVIEWS
} from "~/constants/blocks"

@Component
export default class WrapperBlockComponent extends mixins(Blocks) {
  @Prop({ required: true, type: Object as () => Block }) block: Block
  @Prop({ required: true, type: String }) blockId: string
  @Prop({ default: false, type: Boolean }) virgin: boolean
  @Prop({ default: true, type: Boolean }) lazy: boolean
  @Prop({ default: false, type: Boolean }) isRebrand: boolean

  AV_FAQ: string = AV_FAQ
  AV_FAQ_OLD: string = AV_FAQ_OLD
  BREADCRUMBS: string = BREADCRUMBS
  CORE_MEDIA_TEXT: string = CORE_MEDIA_TEXT
  CORE_QUERY: string = CORE_QUERY
  FAQS_WRAPPER: string = FAQS_WRAPPER
  PAGE_TILE_NAVIGATION: string = PAGE_TILE_NAVIGATION
  PAGE_TILE_NAVIGATION_FULL_IMAGE: string = PAGE_TILE_NAVIGATION_FULL_IMAGE
  TRUSTPILOT_REVIEWS: string = TRUSTPILOT_REVIEWS

  get attributes() {
    return this.virgin ? null : this.Blocks_getAttributes(this.block)
  }
}
