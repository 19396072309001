// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".trustPilot_xoGQV{padding-left:15px;color:#909090}.trustPilot_xoGQV h4{font-size:20px;font-weight:bold;margin-top:11px;margin-bottom:11px}.darkLogo_ZGFMq,.lightLogo_hDxKl{width:100%}.rating_7mYow{width:80%;margin:10px 0 0}@media(min-width: 768px){.rating_7mYow{margin:10px 0}}.dark_tlhWY .lightLogo_hDxKl{display:none}.light_Ay-9x{color:#fff}.light_Ay-9x .darkLogo_ZGFMq{display:none}.light_Ay-9x div a{color:#41a5dd}@media (min-width: 992px){.trustPilot_xoGQV div{width:200px !important}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"trustPilot": "trustPilot_xoGQV",
	"darkLogo": "darkLogo_ZGFMq",
	"lightLogo": "lightLogo_hDxKl",
	"rating": "rating_7mYow",
	"dark": "dark_tlhWY",
	"light": "light_Ay-9x"
};
module.exports = ___CSS_LOADER_EXPORT___;
