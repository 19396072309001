// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".container_FUs2A{padding-right:12vw}.container_FUs2A .swiper-pagination{position:relative;top:0;margin-top:70px;display:flex;width:100vw;justify-content:center;gap:9px}.container_FUs2A .swiper-pagination .swiper-pagination-bullet{height:10px;width:10px;border-radius:3px;--tw-bg-opacity:1;background-color:rgba(255, 255, 255, 1);background-color:rgba(255, 255, 255, var(--tw-bg-opacity));opacity:0.5}.container_FUs2A .swiper-pagination .swiper-pagination-bullet-active{width:20px;opacity:1}@media (min-width: 992px){.container_FUs2A{padding-right:15vw}.container_FUs2A .swiper-pagination{margin-top:60px;width:100%}}@media (min-width: 1200px){.container_FUs2A{padding-right:10vw}}@media (min-width: 1800px){.container_FUs2A{padding-right:22vw}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "container_FUs2A"
};
module.exports = ___CSS_LOADER_EXPORT___;
