// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".selectWrapper_B6\\+XY{padding-top:15px;border-bottom:1px solid #d8d8d8}.input_pmFM5 input,.input_pmFM5 select{font-size:1.125rem}.input_pmFM5 div{font-size:1.125rem;font-weight:400}.customAvSelectIcon_MgJTo{position:absolute;left:10px;top:16px}.customAvSelect_lhsYB{padding-left:30px}.customAvSelect_lhsYB .AvSelect-Label{border-width:0px;background-color:transparent}.categoryWrapper_zSqm6{display:inline-block;width:366px;max-height:54px}.categoryWrapper_zSqm6 input{width:100%;font-size:17px;box-shadow:none;-webkit-appearance:none}.categoryWrapper_zSqm6 div>div{margin-top:5px}.msg_-uYDE{display:flex;border-width:1px;border-style:solid;--tw-border-opacity:1;border-color:rgba(247, 79, 100, 1);border-color:rgba(247, 79, 100, var(--tw-border-opacity));--tw-bg-opacity:1;background-color:rgba(247, 233, 235, 1);background-color:rgba(247, 233, 235, var(--tw-bg-opacity));--tw-text-opacity:1;color:rgba(247, 79, 100, 1);color:rgba(247, 79, 100, var(--tw-text-opacity));padding:7.5px 15px;border-bottom-left-radius:5px;border-bottom-right-radius:5px}.msg_-uYDE .msgTxt_Dzjh8{position:relative;display:inline-block;font-weight:700;font-size:15px;top:-1px;margin-left:15px}@media (min-width: 768px){.categoryWrapper_zSqm6 div>div{margin-top:0}}@media (min-width: 1200px){.selectWrapper_B6\\+XY{padding-top:0}.categoryWrapper_zSqm6{width:500px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectWrapper": "selectWrapper_B6+XY",
	"input": "input_pmFM5",
	"customAvSelectIcon": "customAvSelectIcon_MgJTo",
	"customAvSelect": "customAvSelect_lhsYB",
	"categoryWrapper": "categoryWrapper_zSqm6",
	"msg": "msg_-uYDE",
	"msgTxt": "msgTxt_Dzjh8"
};
module.exports = ___CSS_LOADER_EXPORT___;
