// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.fullNumber_88QU2 {\n\n    display: none;\n\n    font-weight: 700;\n\n    text-decoration-line: none\n}\n.fullNumber_88QU2:hover {\n\n    text-decoration-line: none\n}\n@media (min-width: 768px) {\n.fullNumber_88QU2 {\n\n        display: block\n}\n}\n.fullNumber_88QU2 a {\n\n    text-decoration-line: none\n}\n.fullNumber_88QU2 a:hover {\n\n    --tw-text-opacity: 1;\n\n    color: rgba(255, 255, 255, 1);\n\n    color: rgba(255, 255, 255, var(--tw-text-opacity));\n\n    text-decoration-line: none\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullNumber": "fullNumber_88QU2"
};
module.exports = ___CSS_LOADER_EXPORT___;
