
import Vue from "vue"
import { mapGetters } from "vuex"
import { GET_BREADCRUMBS } from "~/store/pages/getters"
import { removeDomainFromUrl } from "~/utils/block"

export default Vue.extend({
  props: {
    block: {
      required: true,
      type: Object
    }
  },

  head() {
    return {
      // @ts-ignore: Property 'getSchema' does not exist on type CombinedVueInstance
      script: [{ type: "application/ld+json", json: this.getSchema() }]
    }
  },

  computed: {
    ...mapGetters("pages", [GET_BREADCRUMBS]),

    breadcrumbs() {
      if (this.block && this.block.innerBlocks[0] && this.block.innerBlocks[0].innerHtml) {
        return this[GET_BREADCRUMBS]
      }

      return []
    },

    parentLinks() {
      if (this.block && this.block.innerBlocks[0] && this.block.innerBlocks[0].innerHtml) {
        const items = [...this[GET_BREADCRUMBS]]

        items.splice(0, 1)
        items.splice(items.length - 1, 1)

        const links: string[] = []
        items.forEach((entry, index) => {
          const link: string = '<a href="' + entry.url + '">' + entry.text + "</a>"
          links.push(link)
        })

        return links
      }

      return []
    }
  },

  methods: {
    getSchema() {
      const entries: any = this.breadcrumbs
      const list: Record<string, any> = []
      entries.forEach((entry, index) => {
        const path = this.removeDomainFromUrl(entry.url)
        list.push({
          "@type": "ListItem",
          position: index + 1,
          name: entry.text,
          item: this.$config.frontendHost + (path !== this.$config.frontendHost ? path : "")
        })
      })

      if (!entries) {
        return null
      }

      return {
        "@context": "https://schema.org",
        "@type": "BreadcrumbList",
        itemListElement: list
      }
    },

    removeDomainFromUrl(url) {
      // @ts-ignore: this is not identified by TS
      return removeDomainFromUrl(url, this.$config.frontendHost)
    }
  }
})
