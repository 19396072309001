
import Vue from "vue"
import { mapGetters } from "vuex"
import { GET_LOCATION_INFO } from "~/store/location/getters"
import Confetti from "canvas-confetti"

export default Vue.extend({
  name: "AvSavings",
  data() {
    return {
      counterValue: 0 as number,
      intervalId: null as number | null,
      counterComplete: false,
      countingHasStarted: false
    }
  },
  computed: {
    ...mapGetters("location", {
      locationData: GET_LOCATION_INFO
    })
  },
  methods: {
    onVisible() {
      if (!this.counterComplete) {
        this.startCounting()
      }
    },
    startCounting() {
      const targetValue = this.locationData.savings as number
      const duration = 1000 // 1 second
      const steps = 60 // 60 steps per second
      const increment = targetValue / ((duration / 1000) * steps)

      if (!this.countingHasStarted) {
        this.intervalId = window.setInterval(() => {
          if (this.counterValue < targetValue) {
            this.countingHasStarted = true
            this.counterValue = Math.min(this.counterValue + increment, targetValue)
          } else {
            this.stopCounting()
          }
        }, 1000 / steps)
      }
    },
    stopCounting() {
      if (this.intervalId !== null) {
        clearInterval(this.intervalId)
        this.intervalId = null
      }
      setTimeout(() => {
        Confetti({
          particleCount: 100,
          spread: 70,
          origin: { y: 0.8, x: 0.7 }
        })
      }, 200)
      this.counterComplete = true
    }
  },
  beforeDestroy() {
    this.stopCounting()
  }
})
