// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.retrieveQuote_By4O4 .success-container {\n      margin-top: 15px\n}\n.retrieveQuote_By4O4 .success-container svg {\n      margin-left: 10px;\n      margin-top: -5px\n}\n.retrieveQuote_By4O4 .success-container .title {\n      display: inline\n}\n.retrieveQuote_By4O4 .success-container .subtitle {\n      margin-top: 20px\n}\n.retrieveQuote_By4O4 .title {\n      margin-top: 15px;\n      margin-bottom: 15px;\n      font-size: 1.5rem;\n      font-weight: 700;\n      line-height: normal\n}\n.retrieveQuote_By4O4 .subtitle {\n      margin-bottom: 20px;\n      font-size: 1rem;\n      line-height: normal\n}\n.retrieveQuote_By4O4 .text-input {\n      margin-bottom: 8px;\n      font-size: 1rem\n}\n.retrieveQuote_By4O4 button {\n      width: 100%;\n      font-size: 1.25rem\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"retrieveQuote": "retrieveQuote_By4O4"
};
module.exports = ___CSS_LOADER_EXPORT___;
