// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".helpCentreItem_G0tJA {display: flex;width: 100%;cursor: pointer;flex-direction: row;align-items: center;justify-content: flex-start;gap: 16px;color: rgba(0,0,0,0.7);text-decoration-line: none\n}.helpCentreItem_G0tJA:hover {color: rgba(0,0,0,0.7);text-decoration-line: none\n}.helpCentreItem_G0tJA:focus {color: rgba(0,0,0,0.7);text-decoration-line: none\n}.helpCentreItem_G0tJA img {height: 72px;min-height: 0;width: 72px;align-self: flex-start;border-radius: 6px;-o-object-fit: cover;object-fit: cover\n}.helpCentreItem_G0tJA div {display: flex;flex-direction: column;justify-content: flex-start;gap: 4px\n}.helpCentreItem_G0tJA div p:first-of-type {margin-bottom: 0;font-size: 0.875rem;font-weight: 700;text-decoration-line: none\n}.helpCentreItem_G0tJA div p:last-of-type {font-size: 0.813rem;text-decoration-line: none;opacity: 0.5\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"helpCentreItem": "helpCentreItem_G0tJA"
};
module.exports = ___CSS_LOADER_EXPORT___;
