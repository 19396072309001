
import { Component, Getter, Action, Prop, mixins } from "nuxt-property-decorator"
import { extend, ValidationProvider } from "vee-validate"
import { required } from "vee-validate/dist/rules"
import Blocks from "~/mixins/blocks"
import { SET_PICKUP_ADDRESS, SET_LISTING } from "~/store/listing/actions"
import { GET_PICKUP_ADDRESS } from "~/store/listing/getters"
import InitialListing from "~/store/listing/state"
import { placeToAddress } from "~/scripts/convert"

@Component({
  components: { ValidationProvider }
})
export default class NewLocationPickupInput extends mixins(Blocks) {
  @Prop({ required: true, type: Object }) block: Record<string, any>
  @Prop({ default: false, type: Boolean }) virgin: boolean
  @Prop({ default: true, type: Boolean }) lazy: boolean
  @Prop({ default: false, type: Boolean }) validation: boolean
  @Prop({ default: undefined }) deliveryInput: Object

  @Getter(GET_PICKUP_ADDRESS, { namespace: "listing" }) getPickupAddress: Address | null
  @Action(SET_PICKUP_ADDRESS, { namespace: "listing" }) setPickupAddress: Function
  @Action(SET_LISTING, { namespace: "listing" }) setListing: Function

  created() {
    if (this.validation) {
      extend("required", required)
    }
    this.setListing(InitialListing())
  }

  get attributes() {
    return this.virgin ? null : this.Blocks_getAttributes(this.block)
  }

  // eslint-disable-next-line no-undef
  setPickupPlace(place: google.maps.places.PlaceResult | null) {
    this.setPickupAddress(place !== null ? placeToAddress(place) : null)
    if (place !== null && this.deliveryInput) {
      const deliveryInputElement =
        // @ts-ignore-next-line
        this.deliveryInput.$el.children[0]?.children[0]?.children[0]?.children[0]?.children[0]
      if (deliveryInputElement && deliveryInputElement.value === "") {
        deliveryInputElement.focus()
      }
    }
  }
}
