
import { Component, Prop, mixins } from "nuxt-property-decorator"
import axios from "axios"
import { intlFormatDistance } from "date-fns"
import Blocks from "~/mixins/blocks"
import { NO_BUTTONS, NO_PIPS } from "~/constants/blocks"

@Component
export default class TrustpilotCarousel extends mixins(Blocks) {
  @Prop({ required: true, type: Object }) block: Block
  @Prop({ required: true, type: String }) blockId: string

  private id!: string
  private static componentId = 0
  activeSlide: number = 0
  nextSlidesKey: number = 0
  originalSlideCount: number = 0
  minimumSlides: number = 6
  slideInProgress = false
  instantSlideInProgress = false
  buttons: boolean = true
  pips: boolean = true
  slides: Record<string, any>[] = []
  trustPilotError: boolean = false
  filterReviewsByStars: number = 5
  originalSlideCountMax: number = 6
  reviews: Record<string, any>[] = []
  trustpilotTotalReviews: string = "138,669+"
  trustpilotExcellentReviewsRounded: string = "0"
  trustpilotRating: number = 4.6

  async created() {
    if (this.blockId) {
      this.id = this.blockId
    } else {
      TrustpilotCarousel.componentId += 1
      this.id = `trustpilot-${TrustpilotCarousel.componentId}`
    }

    if (this.hasDisabledButtons()) {
      this.buttons = false
    }
    if (this.hasDisabledPips()) {
      this.pips = false
    }

    const trustPilotConfig = this.$config.trustPilot
    let trustPilotId = trustPilotConfig.id
    let trustPilotAPIKey = trustPilotConfig.apiKey
    if (this.$i18n.locale !== "en") {
      const upperCaseLocale = this.$i18n.locale.toUpperCase()
      trustPilotId = trustPilotConfig[`id${upperCaseLocale}`]
      trustPilotAPIKey = trustPilotConfig[`apiKey${upperCaseLocale}`]
    }
    const trustpilotURL = `https://api.trustpilot.com/v1/business-units/${trustPilotId}`
    const trustpilotURLReviews = `https://api.trustpilot.com/v1/business-units/${trustPilotId}/reviews`

    try {
      const axiosInstance = axios.create() as any
      const response = await axiosInstance.get(trustpilotURL, {
        params: {
          apikey: trustPilotAPIKey
        }
      })

      if (response.status === 200 && response.data) {
        if (
          !response.data ||
          !response.data.score.trustScore ||
          !response.data.numberOfReviews.total
        ) {
          this.trustPilotError = true
          return
        }
        this.trustpilotRating = response.data.score.trustScore
        this.trustpilotTotalReviews = Number(response.data.numberOfReviews.total).toLocaleString()
        this.trustpilotExcellentReviewsRounded = Number(
          Math.floor(response.data.numberOfReviews.fiveStars / 1000) * 1000
        ).toLocaleString()
      }

      const responseReviews = await axiosInstance.get(trustpilotURLReviews, {
        params: {
          apikey: trustPilotConfig.apiKey,
          stars: [this.filterReviewsByStars]
        }
      })

      if (responseReviews.status === 200 && responseReviews.data) {
        this.reviews = responseReviews.data.reviews
        this.initSlides()
      }
    } catch (err) {
      this.trustPilotError = true
      console.log("Trustpilot error", err)
    }
  }

  mounted() {
    if (this.reviews && this.reviews.length) {
      this.nextSlidesKey = 0
    }
  }

  get title() {
    return this.block.innerBlocks[0].innerBlocks[0].innerBlocks[0].innerHtml.replace(
      new RegExp("<h2[^>]*>+(.*)<\/h2>"),
      "$1"
    )
  }

  get ratingString() {
    const rating = this.$t("trustpilot.rating").toString()
    return rating
      .replace("RATING", `${this.trustpilotRating}`)
      .replace("REVIEWCOUNT", this.trustpilotTotalReviews)
  }

  get localeLink() {
    const trustPilotLinks = {
      en: "https://uk.trustpilot.com/review/anyvan.com",
      es: "https://es.trustpilot.com/review/anyvan.es",
      fr: "https://fr.trustpilot.com/review/anyvan.fr",
      de: "https://de.trustpilot.com/review/anyvan.de",
      it: "https://it.trustpilot.com/review/anyvan.it"
    }
    if (!this.$i18n.locale) {
      return trustPilotLinks.en
    }
    return trustPilotLinks[this.$i18n.locale]
  }

  reviewHref(href) {
    return href.replace("api.trustpilot.com/v1/reviews/", "www.trustpilot.com/reviews/")
  }

  formatDistance(date) {
    const options: any = { style: "narrow", addSuffix: false, numeric: "always" }
    if (this.$i18n.locale !== "en") {
      options.locale = this.$i18n.locale
    }
    return intlFormatDistance(new Date(date), new Date(), options)
  }

  initSlides() {
    if (this.reviews && this.reviews.length) {
      if (!this.originalSlideCount) {
        this.slides = [...this.reviews.slice(0, this.originalSlideCountMax)]
        this.originalSlideCount = this.slides.length
        if (this.originalSlideCount * 2 > this.minimumSlides) {
          this.minimumSlides = this.originalSlideCount * 2
        }
        this.cloneOriginalSlides()
      }
      this.nextSlidesKey = 0
    }
  }

  cloneOriginalSlides() {
    let slidesCount = this.slides.length
    let slidesClone: Record<string, any>[] = [...this.slides]
    if (this.originalSlideCount === 1) {
      slidesClone = Array(5).fill(this.slides[0])
    } else if (this.originalSlideCount === 2) {
      slidesClone = [...this.slides, ...this.slides]
    }

    while (slidesCount < this.minimumSlides) {
      const nextSlideId = slidesCount - this.originalSlideCount
      this.slides.push(slidesClone[nextSlideId])
      slidesCount++
    }
    this.nextSlidesKey = this.minimumSlides - this.originalSlideCount
  }

  // Common carousel functions below
  handleScroll(event) {
    if (this.slideInProgress) {
      this.stopSlide()
      return
    }
    let actualSlide = 0
    let exactSlide = true
    let cardMargin = 32 // For mobile
    if (window.innerWidth >= 1200) {
      cardMargin = 48 // For desktop
    }
    const itemWidth = event.target.firstChild.firstChild.offsetWidth + cardMargin
    const scrollLeftOriginal = Math.round(event.target.scrollLeft)

    if (scrollLeftOriginal === 0) {
      this.activeSlide = 0
    } else if (scrollLeftOriginal % itemWidth === 0) {
      actualSlide = scrollLeftOriginal / itemWidth
    } else {
      exactSlide = false
      const scrollLeft = scrollLeftOriginal - (scrollLeftOriginal % itemWidth)
      actualSlide = scrollLeft / itemWidth
    }

    if (actualSlide >= this.originalSlideCount) {
      actualSlide = actualSlide - this.originalSlideCount
      this.activeSlide = actualSlide
      if (exactSlide) {
        this.instantScrollToActiveItem()
      } else {
        this.$nextTick(() => {
          // @ts-ignore
          this.$scrollTo(`#${this.id}-${this.activeSlide + this.originalSlideCount}`, 0, {
            container: `#${this.id}`,
            x: true,
            y: false,
            offset: -16,
            onStart: this.startSlide,
            onDone: this.instantScrollToActiveItem
          })
        })
      }
    }
    this.activeSlide = actualSlide
  }

  isActiveSlide(index) {
    let slideToCompare = this.activeSlide + 1
    if (slideToCompare > this.originalSlideCount) {
      slideToCompare = 1
    }
    return index === slideToCompare
  }

  scrollLeft() {
    this.startSlide()
    if (this.activeSlide > 0) {
      this.activeSlide--
      this.scrollTo(this.activeSlide)
    }
  }

  scrollRight() {
    this.startSlide()
    this.activeSlide++
    if (this.activeSlide >= this.originalSlideCount) {
      if (this.activeSlide < this.slides.length - 1) {
        this.$nextTick(() => {
          // @ts-ignore
          this.$scrollTo(`#${this.id}-${this.activeSlide}`, 0, {
            container: `#${this.id}`,
            x: true,
            y: false,
            offset: -16,
            onStart: this.startSlide,
            onDone: this.instantScrollToFirstItem
          })
        })
      }
    } else {
      this.animatedScrollToItem()
    }
  }

  animatedScrollToItem() {
    if (this.activeSlide < this.slides.length - 1) {
      this.scrollTo(this.activeSlide)
    }
  }

  instantScrollToFirstItem() {
    if (this.activeSlide >= this.originalSlideCount) {
      this.activeSlide = 0
      this.instantScrollToActiveItem()
    }
  }

  instantScrollToActiveItem() {
    this.instantScrollTo(this.activeSlide)
  }

  instantScrollTo(slide) {
    setTimeout(() => {
      // @ts-ignore
      this.$scrollTo(`#${this.id}-${slide}`, 0, {
        container: `#${this.id}`,
        x: true,
        y: false,
        offset: -16,
        onStart: this.startInstantSlide,
        onDone: this.stopInstantSlide
      })
    }, 500)
  }

  scrollTo(slide) {
    this.$nextTick(() => {
      // @ts-ignore
      this.$scrollTo(`#${this.id}-${slide}`, 0, {
        container: `#${this.id}`,
        x: true,
        y: false,
        offset: -16,
        onStart: this.startSlide
      })
    })
  }

  startInstantSlide() {
    this.instantSlideInProgress = true
    this.startSlide()
  }

  stopInstantSlide() {
    this.instantSlideInProgress = false
  }

  startSlide() {
    this.slideInProgress = true
  }

  stopSlide() {
    this.slideInProgress = false
  }

  hasDisabledButtons() {
    return this.Blocks_patternCheck(this.block.attributes, NO_BUTTONS)
  }

  hasDisabledPips() {
    return this.Blocks_patternCheck(this.block.attributes, NO_PIPS)
  }
}
