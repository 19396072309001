// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.image-dialog {\n  align-content: flex-end;\n}\n.dialogLayout_Dpbx8 {\n  display: flex;\n  justify-content: space-around;\n}\n.dialogLayout_Dpbx8 .av-heading,\n    .dialogLayout_Dpbx8 .av-paragraph {\n  margin-bottom: 24px;\n}\n.dialogLayout_Dpbx8 .av-button {\n  margin-top: 0;\n  text-align: center;\n}\n.boxContainer_2vfLt {\n  display: flex;\n  flex-direction: column;\n  padding: 16px;\n  border-radius: 16px;\n  margin-top: 262px;\n  max-width: 397px;\n  box-shadow: 0 -16px 32px 0 rgba(0, 0, 0, 0.5);\n}\n@media (min-width: 768px) {\n.dialogLayout_Dpbx8 .av-button {\n    width: 100%;\n}\n.boxContainer_2vfLt {\n    margin-top: 0;\n    align-self: flex-end;\n    max-width: none;\n    max-width: initial;\n    width: 512px;\n    box-shadow: 0 -16px 16px 0 rgba(0, 0, 0, 0.25);\n}\n}\n@media (min-width: 1200px) {\n.dialogLayout_Dpbx8 {\n    justify-content: flex-end;\n}\n.dialogLayout_Dpbx8 .av-button {\n        width: inherit;\n}\n.boxContainer_2vfLt {\n    margin-left: 0;\n    margin-right: 0;\n    padding: 48px;\n    box-shadow: 0 16px 32px 0 rgba(0, 0, 0, 0.5);\n    width: 576px;\n}\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialogLayout": "dialogLayout_Dpbx8",
	"boxContainer": "boxContainer_2vfLt"
};
module.exports = ___CSS_LOADER_EXPORT___;
