
import Vue from "vue"
import { Getter } from "vuex-class"
import { Component, Prop, Watch } from "vue-property-decorator"
import { GET_EXPERIMENTS_DATA } from "~/store/experiments/getters"

@Component
export default class ExperimentComponent extends Vue {
  @Prop({ required: true, type: Object as () => Block }) block: Block
  @Prop({ type: Boolean, default: true }) lazy!: boolean

  @Getter(GET_EXPERIMENTS_DATA, { namespace: "experiments" }) experiments: any

  displayBlock: boolean = false

  @Watch("experiments")
  onExperimentDataChange() {
    this.updateDisplayBlock()
  }

  updateDisplayBlock(): void {
    if (!this.experimentData) {
      return
    }
    // check if we have experiment our experiment in vuex
    if (this.experiments && this.experiments.get(this.experimentData.experimentName)) {
      const testLabVariant = this.experiments.get(this.experimentData.experimentName)
      this.displayBlock = testLabVariant === this.experimentData.variantName
      return
    }
    this.displayBlock = this.experimentData.variantName === "control"
  }

  getExperimentDataFromAttributes() {
    const attributes = this.block.attributes
    const classNameAttribute = attributes.find(attr => attr.name === "className")
    if (classNameAttribute && classNameAttribute.value) {
      const classNames = classNameAttribute.value.split(" ")
      const experimentName = classNames.find(name => name.startsWith("avx-name-"))
      const variantName = classNames.find(name => name.startsWith("avx-variant-"))
      const className = classNames.find(name => name !== "av-experiment" && name.startsWith("av-"))

      return {
        experimentName: experimentName ? experimentName.substring("avx-name-".length) : "",
        variantName: variantName ? variantName.substring("avx-variant-".length) : ""
      }
    }
  }

  get experimentData() {
    if (this.block.experiment !== undefined) {
      return this.block.experiment
    }
    this.block.experiment = this.getExperimentDataFromAttributes()
    return this.block.experiment
  }
}
