// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.panelContainer_eepWQ {\ndisplay: flex;\nflex-direction: column;\ngap: 16px\n}\n@media (min-width: 768px) {\n.panelContainer_eepWQ {\ndisplay: grid\n}\n}\n@media (min-width: 992px) {\n.panelContainer_eepWQ {\nalign-items: center\n}\n}\n@media (min-width: 768px) {\n.panelContainer_eepWQ {\n    grid-template-columns: 200px 1fr 1fr\n}\n}\n@media (min-width: 992px) {\n.panelContainer_eepWQ {\n    grid-template-columns: 1fr 1fr\n}\n}\n.phoneButton_GqhN7 {\n  margin-left: 0 !important; /* Overrides pesky margin in Btn component */\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panelContainer": "panelContainer_eepWQ",
	"phoneButton": "phoneButton_GqhN7"
};
module.exports = ___CSS_LOADER_EXPORT___;
