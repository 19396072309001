
import { Vue, Component, Prop } from "nuxt-property-decorator"
import { MAP_LANGUAGE_CODES } from "~/constants/locale"

@Component
export default class TheFooterContainer extends Vue {
  // TODO All LocalisedTheFooter components should be fixed
  // as they should rely on GET_PHONE_NUMBER_VALUE (from footer.ts) instead of page.customPhoneNumber provided
  @Prop({ required: true }) page: Record<string, any>
  @Prop({ required: false, default: false }) isRebrand: boolean

  get MAP_LANGUAGE_CODES() {
    return MAP_LANGUAGE_CODES
  }
}
