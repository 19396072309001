// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.loginButton_E4AtM {\n  display: none;\n  --tw-text-opacity: 1;\n  color: rgba(255, 255, 255, 1);\n  color: rgba(255, 255, 255, var(--tw-text-opacity));\n  font-weight: 700;\n  align-items: center;\n}\n.loginButton_E4AtM a {\n    color: inherit;\n    -webkit-text-decoration: none;\n    text-decoration: none;\n}\n.dropdownTrigger_fWZqq {\n  position: relative;\n  cursor: pointer;\n  padding-left: 10px;\n  padding-right: 15px;\n  align-self: center;\n  height: 100%;\n  display: flex;\n  align-items: center;\n}\n.dropdown_z64C- {\n  position: absolute;\n  top: 100%;\n  right: 0;\n  z-index: 1000;\n  min-width: 160px;\n  color: #333333;\n  background-color: #fff;\n  border: 1px solid rgba(0, 0, 0, 0.15);\n  border-radius: 0 0 4px 4px;\n  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);\n  background-clip: padding-box;\n  overflow: hidden;\n}\n.dropdown_z64C- div {\n  display: block;\n  padding: 12px 20px;\n  font-weight: normal;\n  line-height: 1.42857143;\n  white-space: nowrap;\n  border-bottom: 1px solid #e5e5e5;\n}\n.dropdown_z64C- li:last-child div {\n  border-bottom: none;\n}\n.dropdown_z64C- li:hover,\n.dropdown_z64C- li:focus {\n  background-color: #f5f5f5;\n}\n@media (min-width: 768px) {\n.loginButton_E4AtM {\n    display: flex;\n}\n.loginButton_E4AtM a {\n      display: flex;\n      height: 100%;\n      padding-left: 8px;\n      padding-right: 8px;\n}\n.loginButton_E4AtM a svg,\n      .loginButton_E4AtM a span {\n        margin-top: auto;\n        margin-bottom: auto;\n}\n.loginButton_E4AtM:hover,\n  .loginButton_E4AtM:focus {\n    background-color: #3991c4;\n}\n.dropdown_z64C- {\n    right: -16px;\n}\n}\n@media (min-width: 1200px) {\n.dropdown_z64C- {\n    right: 0;\n}\n}\n.loginButtonText_-jc7\\+ {\n  display: none;\n}\n@media (min-width: 1200px) {\n.loginButton_E4AtM .loginButtonText_-jc7\\+ {\n    display: inline;\n    display: initial;\n    margin-left: 10px;\n}\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loginButton": "loginButton_E4AtM",
	"dropdownTrigger": "dropdownTrigger_fWZqq",
	"dropdown": "dropdown_z64C-",
	"loginButtonText": "loginButtonText_-jc7+"
};
module.exports = ___CSS_LOADER_EXPORT___;
