
export default {
  name: "CheckListItem",

  props: {
    content: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: "#7ed321"
    },
    hasDesktopView: {
      type: Boolean,
      default: false
    }
  }
}
