
import Vue from "vue"

export default Vue.extend({
  name: "NavHelpCentreItem",
  props: {
    url: {
      type: String,
      default: ""
    },
    imageSrc: {
      type: String,
      default: null
    }
  }
})
