import { render, staticRenderFns } from "./FaqsOriginal.vue?vue&type=template&id=445005b7&lang=pug"
import script from "./FaqsOriginal.vue?vue&type=script&lang=js"
export * from "./FaqsOriginal.vue?vue&type=script&lang=js"
import style0 from "./FaqsOriginal.vue?vue&type=style&index=0&id=445005b7&prod&lang=scss&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AccordionOriginal: require('/app/components/generic/AccordionOriginal.vue').default,Block: require('/app/components/generic/Block.vue').default})
