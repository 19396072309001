// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n@media (min-width: 992px) {\n.wrapper_agjWy {\n    grid-template-columns: repeat(2, 1fr);\n    grid-template-areas:\n      \"heading heading\"\n      \"uspList savingsCallout\"\n}\n.heading_r765Y {\n    grid-area: heading\n}\n.uspList_UkCKj {\njustify-content: space-evenly;\n    grid-area: uspList\n}\n}\n@media (min-width: 768px) {\n.uspText_gBQvL p {\nfont-size: 1.25rem\n}\n}\n@media (min-width: 992px) {\n.savingsCallout_\\+7\\+2o {\n    grid-area: savingsCallout\n}\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "wrapper_agjWy",
	"heading": "heading_r765Y",
	"uspList": "uspList_UkCKj",
	"uspText": "uspText_gBQvL",
	"savingsCallout": "savingsCallout_+7+2o"
};
module.exports = ___CSS_LOADER_EXPORT___;
