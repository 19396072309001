import { NuxtRuntimeConfig } from "@nuxt/types/config/runtime";
import {
  finalApolloClient,
  gql,
} from './../graphql-client'

const availableTagsQuery = gql`
  query GetTags {
    tags {
      edges {
        node {
          id
          slug
        }
      }
    }
}
`

export class TagsService {
  constructor(private config: NuxtRuntimeConfig) {
  }

  async getTags() {
    const tagResponse = await finalApolloClient({
      uri: this.config.graphqlEndpoint,
    }).query({
      query: availableTagsQuery,
      fetchPolicy: 'no-cache',
    })

    const tags:Tag[] = []

    if (tagResponse.data && tagResponse.data.tags) {
      tagResponse.data.tags.edges.forEach((tagEdge) => {
        const tag:Tag = <Tag>tagEdge.node
        tags.push(tag)
      })
    }

    return tags
  }
}
