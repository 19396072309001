import { render, staticRenderFns } from "./CoreCover.vue?vue&type=template&id=1e40b86c&lang=pug"
import script from "./CoreCover.vue?vue&type=script&lang=ts"
export * from "./CoreCover.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {OptimizedImage: require('/app/components/generic/OptimizedImage.vue').default,Overlay: require('/app/components/parts/Overlay.vue').default})
