
import IconAngle from "./IconAngle.vue"
import IconX from "./IconX.vue"
import Popper from "./AvPopper.vue"

export default {
  name: "AvSelect",
  components: { IconX, IconAngle, Popper },
  props: {
    styles: {
      type: Object,
      default: () => ({})
    },
    type: {
      type: String,
      default: "primary"
    },
    size: {
      type: String,
      default: "md"
    },
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: [String, Number, Boolean, Object, Array],
      default: null
    },
    searchableValue: {
      type: String,
      default: "label"
    },
    placeholder: {
      type: String,
      default: "Select an option"
    },
    noOptionsPlaceholder: {
      type: String,
      default: "No options to show"
    },
    fullscreenDropdown: {
      type: Boolean,
      default: false
    },
    dropdownMaxHeight: {
      type: String,
      default: "50vh"
    },
    lockInitialWidth: {
      type: Boolean,
      default: false
    },
    defaultOptionStyles: {
      type: Boolean,
      default: true
    },
    noClearButton: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: false
    },
    customSearchFilter: {
      type: Function,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    iconPrefix: {
      type: String,
      default: ""
    },
    iconSize: {
      type: Number,
      default: 32
    },
    optionIcon: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      config: {},
      selectedOption: null,
      isDropdown: false,
      dropdownWidth: 0,
      dropdownWidthObserver: null,
      searchingPhrase: ""
    }
  },
  computed: {
    isSelectedSlot() {
      return this.$scopedSlots.selectedOption
    },
    filteredOptions() {
      if (this.searchable) {
        if (this.customSearchFilter) {
          return this.customSearchFilter(this.options, this.searchingPhrase)
        }
        return this.options.filter(option => {
          return option[this.searchableValue]
            .toLowerCase()
            .includes(this.searchingPhrase.toLowerCase())
        })
      } else {
        return this.options
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val) {
        this.setValue(val)
      }
    },
    isDropdown() {
      if (this.isDropdown && this.fullscreenDropdown) {
        document.querySelector("body").style.overflow = "hidden"
      } else if (!this.isDropdown && this.fullscreenDropdown) {
        document.querySelector("body").style.overflow = "auto"
      }

      if (!this.searchable) {
        return
      }
      if (this.isDropdown) {
        this.$nextTick(() => {
          if (this.$refs.searchInput) {
            this.$refs.searchInput.focus()
          }
        })
      } else {
        this.searchingPhrase = ""
      }
    }
  },
  created() {
    const merge = function (obj) {
      const extended = {}
      for (const prop in obj) {
        if (obj.hasOwnProperty(prop)) {
          // Push each value from `obj` into `extended`
          extended[prop] = obj[prop]
        }
      }
      return extended
    }
    this.config = merge(
      this.$defaults ? this.$defaults.AvSelect : {},
      this.$projectConfig ? this.$projectConfig.AvSelect : {}
    )
    this.setValue(this.value)
  },
  mounted() {
    this.updateDropdownWidth()
  },
  beforeDestroy() {
    if (this.dropdownWidthObserver) {
      this.dropdownWidthObserver.disconnect()
    }
  },
  methods: {
    selectOption(option, index) {
      this.selectedOption = option
      this.$emit("input", option)
      this.$emit("select", { option, index })
      this.hide()
    },
    clearSelectedOption(e) {
      e.stopPropagation()
      this.selectedOption = null
      this.$emit("input", null)
      this.$emit("clear")
    },
    hide() {
      this.$refs.Popper.hide()
    },
    setValue(value) {
      this.selectedOption = this.options.find(option => option === value)
    },
    // Option icon
    parsedIcon(option) {
      let icon = option.value
      if (icon === "") {
        icon = "back"
      } else {
        icon = this.iconPrefix + icon.toLowerCase().replace(" ", "-").replace(" ", "-")
      }

      return icon
    },
    updateDropdownWidth() {
      const labelElement = this.$refs.label
      if (labelElement) {
        this.dropdownWidth = labelElement.clientWidth

        this.dropdownWidthObserver = new ResizeObserver(() => {
          this.dropdownWidth = labelElement.clientWidth
        })

        this.dropdownWidthObserver.observe(labelElement)
      }
    }
  }
}
