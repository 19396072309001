
import { Component, Prop, mixins } from "nuxt-property-decorator"
import Blocks from "~/mixins/blocks"
import ImageUrlS3 from "~/mixins/imageUrlS3"

interface blockData {
  imageURL: string
  mobileImageURL: string
  tabletImageURL: string
  dialogContent: any[]
  dialogClass: string
}

@Component({})
export default class ImageDialog extends mixins(Blocks, ImageUrlS3) {
  @Prop({ required: true, type: Object }) block: Record<string, any>
  @Prop(Object) readonly data!: blockData

  blockData: blockData = {
    imageURL: "",
    mobileImageURL: "",
    tabletImageURL: "",
    dialogContent: [],
    dialogClass: ""
  }

  mounted() {
    this.mapData()
  }

  mapData() {
    this.blockData.imageURL = this.block.innerBlocks[0].attributes.find(
      item => item.name === "url"
    ).value
    this.blockData.tabletImageURL = this.blockData.imageURL
    if (this.block.innerBlocks[1]) {
      this.blockData.tabletImageURL = this.block.innerBlocks[1]?.attributes.find(
        item => item.name === "url"
      ).value
    }
    this.blockData.mobileImageURL = this.blockData.tabletImageURL
    if (this.block.innerBlocks[2]) {
      this.blockData.mobileImageURL = this.block.innerBlocks[2]?.attributes.find(
        item => item.name === "url"
      ).value
    }

    document.documentElement.style.setProperty(
      "--dialog-section-background",
      `url(${this.replaceUrlS3(this.blockData.imageURL)})`
    )
    document.documentElement.style.setProperty(
      "--dialog-section-tablet-background",
      `url(${this.replaceUrlS3(this.blockData.tabletImageURL)})`
    )
    document.documentElement.style.setProperty(
      "--dialog-section-mobile-background",
      `url(${this.replaceUrlS3(this.blockData.mobileImageURL)})`
    )

    this.blockData.dialogContent =
      this.block.innerBlocks[0].innerBlocks[1].innerBlocks[1].innerBlocks[0].innerBlocks
    this.blockData.dialogClass = this.Blocks_getAttributes(
      this.block.innerBlocks[0].innerBlocks[1].innerBlocks[1].innerBlocks[0]
    ).class
  }
}
