
import Vue from "vue"

export default Vue.extend({
  name: "NavButton",
  props: {
    chevron: {
      type: Boolean,
      default: false
    },
    toggled: {
      type: Boolean,
      default: false
    }
  }
})
