
import { Vue, Component, Prop, mixins } from 'nuxt-property-decorator'
import Blocks from '~/mixins/blocks'

@Component
export default class CoreCoverComponent extends mixins(Blocks) {
  @Prop({ required: true, type: Object }) block: Record<string, any>
  @Prop({ default: false, type: Boolean }) virgin: boolean
  @Prop({ default: true, type: Boolean }) lazy: boolean

  get imageData() {
    const imageUrl = this.block.attributes.find(
      (attribute) => attribute.name === 'url'
    )

    const imageId = this.block.attributes.find(
      (attribute) => attribute.name === 'id'
    )

    return { url: imageUrl.value, id: imageId.value }
  }

  get attributes() {
    return this.virgin ? null : this.Blocks_getAttributes(this.block)
  }
}
