
import { Component } from "nuxt-property-decorator"
import Header from "~/mixins/header"

@Component
export default class TheHeaderESComponent extends Header {
  isMouseInside = false
  mouseTimeoutID: any

  handleMouseEnterPanelMenu() {
    this.isMouseInside = true
    if (this.mouseTimeoutID) {
      clearTimeout(this.mouseTimeoutID)
    }
  }

  handleMouseLeavePanelMenu() {
    this.isMouseInside = false
    this.mouseTimeoutID = setTimeout(() => {
      if (!this.isMouseInside) {
        this.hidePanelMenu()
      }
    }, 500)
  }

  hidePanelMenu() {
    this.showPanelMenu = false
    this.showMenuSection = false
  }

  handleMenuLeave() {
    this.handleMouseLeavePanelMenu()
  }

  get iframeSource() {
    let baseURL = this.$config.v4Host.replace(".com", ".es") + "/territory/track-page-view" + "?landing_source=cms&landing_path=" + this.$route.path
    if (Object.keys(this.$route.query).length > 0) {
      // @ts-ignore
      const queryString = new URLSearchParams(this.$route.query).toString()
      baseURL += "&" + queryString
    }
    return baseURL
  }
}
