
import { getBlockAttribute } from "~/utils/block"

export default {
  name: "AvAccordion",

  props: {
    block: {
      type: Object, // Record<string, any>
      required: true
    },
    lazy: {
      type: Boolean,
      default: true
    },
    active: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: null
    }
  },
  computed: {
    heading() {
      if (!this.block) {
        return ""
      }
      return getBlockAttribute(this.block.attributes, "question")
    }
  },
  methods: {
    setActive() {
      this.$emit("clicked", this.index)
    }
  }
}
