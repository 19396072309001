
import { Component, Getter, Action, Prop, mixins } from "nuxt-property-decorator"
import { ValidationProvider, extend } from "vee-validate"
import { required } from "vee-validate/dist/rules"
import Blocks from "~/mixins/blocks"
import { SET_LISTING, SET_CATEGORY } from "~/store/listing/actions"
import { GET_CATEGORY } from "~/store/listing/getters"
import InitialListing from "~/store/listing/state"
import { CATEGORY_LIST, CATEGORY_ID_MORE } from "~/constants/category"
import { getCategoryById, getCategoryIcon } from "~/services/public/Listing"

@Component({
  components: { ValidationProvider }
})
export default class CategoryInput extends mixins(Blocks) {
  listingCategoryOption: OptionString | null = null
  listingCategories: OptionString[] = CATEGORY_LIST

  @Prop({ required: true, type: Object }) block: Record<string, any>
  @Prop({ default: false, type: Boolean }) virgin: boolean
  @Prop({ default: true, type: Boolean }) lazy: boolean
  @Prop({ default: false, type: Boolean }) validation: boolean

  @Getter(GET_CATEGORY, { namespace: "listing" }) getCategory: CategoryID | null

  @Action(SET_CATEGORY, { namespace: "listing" }) setCategory: Function
  @Action(SET_LISTING, { namespace: "listing" }) setListing: Function

  created() {
    if (this.validation) {
      extend("required", required)
    }
    this.setListing(InitialListing())
  }

  inputListingCategory(selected) {
    this.listingCategoryOption = this.listingCategories.filter(
      item => item.value === selected.value
    )[0]
    this.setCategory(selected.value)
  }

  selectListingCategory(selected) {
    this.listingCategoryOption = this.listingCategories[selected.index]
    this.setCategory(selected.option.value)
  }

  get attributes() {
    return this.virgin ? null : this.Blocks_getAttributes(this.block)
  }

  get categoryIcon(): string {
    return this.iconByCategory(this.getCategory)
  }

  iconByCategory(value: CategoryID | null): string {
    let icon: string | undefined = "category-placeholder"

    if (value) {
      icon = this.getCategoryById(value)?.icon
      if (!icon) {
        icon = "category-" + CATEGORY_ID_MORE
      }
    }

    return icon
  }

  getCategoryById(id: string): Category | null {
    return getCategoryById(id)
  }

  getCategoryIcon(icon: string | undefined): Array<string> {
    return getCategoryIcon(icon)
  }
}
