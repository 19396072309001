
import Vue from "vue"

export default Vue.extend({
  name: "NavHelpCentre",
  data() {
    return {
      showMenu: false
    }
  },
  methods: {
    toggleMenu(override: null | boolean = null) {
      if (override !== null) {
        this.showMenu = override
      } else {
        this.showMenu = !this.showMenu
      }

      if (this.showMenu) {
        this.$emit("hide-other-menus")
      }
    },
    closeAllMenus() {
      this.toggleMenu(false)
      this.$emit("hide", false)
    }
  }
})
