// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button_BzZ8e{display:flex;height:30px;align-items:center;border-width:1px;--tw-border-opacity:1;border-color:rgba(255, 255, 255, 1);border-color:rgba(255, 255, 255, var(--tw-border-opacity));padding-left:8px;padding-right:8px;text-decoration-line:none;border-radius:4px}.button_BzZ8e:hover{background-color:#79b8e2}.phoneNumber_HaxQz{display:inline-flex;align-items:center;text-decoration-line:none}.phoneNumber_HaxQz svg{margin-right:8px;top:auto;top:initial}.nums_7n3xl{font-size:22px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "button_BzZ8e",
	"phoneNumber": "phoneNumber_HaxQz",
	"nums": "nums_7n3xl"
};
module.exports = ___CSS_LOADER_EXPORT___;
