
import { Vue, Component, Prop } from 'nuxt-property-decorator'

@Component
export default class AnyVanLogoComponent extends Vue {
  @Prop({ type: String, default: 'white' }) color: string
  @Prop({ type: Number, default: 140 }) width: number
  @Prop({ type: Number, default: 24 }) height: number
  @Prop({ type: Boolean, default: true }) full: boolean
  @Prop({ type: Boolean, default: false }) withoutLink: boolean

  get homeLink() {
    switch (this.$i18n.locale) {
      case "en":
        return this.$config.v4Host
      case "es":
        return this.$config.v4Host + "/es"
      case "fr":
        return this.$config.v4Host.replace(".com", ".fr")
      case "de":
        return this.$config.v4Host.replace(".com", ".de")
      case "it":
        return this.$config.v4Host.replace(".com", ".it")
      default:
        return this.$config.v4Host
    }
  }
}
