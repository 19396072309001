
import { Vue, Prop, Component } from "nuxt-property-decorator"
import { library } from "@fortawesome/fontawesome-svg-core"
import { VueSvgIcon } from "@yzfe/vue-svgicon"
import icons from "~/scripts/icons"

@Component({
  components: {
    icon: VueSvgIcon
  }
})
export default class IconComponent extends Vue {
  @Prop({ type: Number, default: 16 }) size: number
  @Prop({ type: Number, default: 0 }) sizeX: number
  @Prop({ type: Number, default: 0 }) sizeY: number
  @Prop({ type: Array, default: null }) icon: string[] | null
  @Prop({ type: Array, default: () => [] }) icons: any[]
  @Prop({ type: String, default: "" }) stroke: string

  get parsedIcons() {
    let vbWidth = 0
    let vbHeight = 0
    const iconsArray = [this.icon !== null ? [...this.icon] : null, ...this.icons]

    const picons = iconsArray.filter(Boolean).map(icon => {
      // @ts-ignore -- Property 'definitions' does not exist on type 'Library'.
      const faIcon = library.definitions[icon[0]][icon[1]]

      if (faIcon && faIcon.length === 5) {
        if (faIcon[0] > vbWidth) {
          vbWidth = faIcon[0]
        }

        if (faIcon[1] > vbHeight) {
          vbHeight = faIcon[1]
        }

        if (icon.length === 2) {
          icon.push("")
        }

        if (icon.length !== 4) {
          icon.push("")
        }

        icon.push(faIcon[4])
      }

      return icon
    })
    picons.unshift(vbWidth, vbHeight)
    return picons
  }

  get getSvgIcon() {
    const svg = icons.find(v => v.name === this.icon![1])
    if (svg && svg.name === "ground") {
      svg.data.data = svg.data.data.replace("_fill", "fill")
    }
    if (svg && svg.data.data) {
      svg.data.data = svg.data.data.replace(/_fill="#[0-9a-zA-Z]+"/, "")
      svg.data.data = svg.data.data.replace(/pid="[0-9]+"/, "")
    }

    return svg
  }
}
