
import { Vue, Component } from 'nuxt-property-decorator'
import 'swiper/swiper-bundle.min.css'
import { Swiper } from 'swiper/bundle'

@Component
export default class CarouselComponent extends Vue {
  baseParams: Record<string, any> = {
    autoplay: {
      delay: 4000,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    slidesPerView: 1,
    breakpoints: {
      1024: {
        slidesPerView: 2,
        autoplay: false,
        noSwiping: true,
        initialSlide: 0,
        spaceBetween: 30,
      },
    },
    spaceBetween: 24,
  }

  mySwiper: Record<string, any> = {}

  mounted() {
    this.mySwiper = new Swiper('.swiper-container', this.baseParams)
  }
}
