// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".image_o-Tp3.loading{opacity:0;transition:opacity 2s;background:rgba(0,0,0,0) url(\"data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3Csvg width='44' height='44' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100' preserveAspectRatio='xMidYMid' class='uil-ring'%3E%3Crect x='0' y='0' width='100' height='100' fill='none' class='bk'%3E%3C/rect%3E%3Ccircle cx='50' cy='50' r='45.5' stroke-dasharray='207.26657532058658 78.61835615608459' stroke='%2341a5dd' fill='none' stroke-width='9'%3E%3CanimateTransform attributeName='transform' type='rotate' values='0 50 50;180 50 50;360 50 50;' keyTimes='0;0.5;1' dur='1s' repeatCount='indefinite' begin='0s'%3E%3C/animateTransform%3E%3C/circle%3E%3C/svg%3E\") no-repeat center center}.image_o-Tp3{opacity:1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image": "image_o-Tp3"
};
module.exports = ___CSS_LOADER_EXPORT___;
