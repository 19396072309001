
import { getBlockAttributes } from "~/utils/block"
import { COMPARISON_TABLE_REVERSE } from "~/constants/blocks"

export default {
  name: "ComparisonTableSection",

  props: {
    block: {
      type: Object,
      required: true
    },
    lazy: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    getReverseClass(block) {
      return getBlockAttributes(block).class.includes(COMPARISON_TABLE_REVERSE)
        ? "comparison-table-reverse"
        : ""
    }
  }
}
