
import { Vue, Component, Prop } from "nuxt-property-decorator"
import { MAP_LANGUAGE_CODES } from "~/constants/locale"

@Component
export default class TheHeaderContainer extends Vue {
  // TODO All LocalisedTheHeader components should be fixed
  // as they should rely on GET_PHONE_NUMBER_VALUE (from header.ts) instead of page.customPhoneNumber provided
  @Prop({ required: true }) page: Record<string, any>
  @Prop({ default: false, type: Boolean }) isRebrand: boolean

  get MAP_LANGUAGE_CODES() {
    return MAP_LANGUAGE_CODES
  }
}
