// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.otherServicesSection_7gfWX > *:nth-child(2) {\n      display: none;\n}\n@media (min-width: 768px) {\n.otherServicesSection_7gfWX .av-heading {\n            margin-bottom: 32px;\n            text-align: center;\n}\n.otherServicesSection_7gfWX .av-link-table {\n            justify-content: center;\n}\n}\n@media (min-width: 992px) {\n.otherServicesSection_7gfWX {\n            display: grid;\n    grid-template-rows: auto auto;\n    grid-template-columns: 5fr 1fr 6fr;\n    grid-template-areas:\n      \"image gap heading\"\n      \"image gap table\";\n}\n.otherServicesSection_7gfWX.reverse {\n      grid-template-columns: 6fr 1fr 5fr;\n      grid-template-areas:\n        \"heading gap image\"\n        \"table gap image\";\n}\n.otherServicesSection_7gfWX > *:nth-child(1) {\n      grid-area: heading;\n}\n.otherServicesSection_7gfWX > *:nth-child(2) {\n      grid-area: image;\n      display: block;\n}\n.otherServicesSection_7gfWX > *:nth-child(3) {\n      grid-area: table;\n}\n.otherServicesSection_7gfWX .av-heading {\n            margin-bottom: 16px;\n            align-self: center;\n            text-align: left;\n}\n.otherServicesSection_7gfWX .av-link-table {\n            justify-content: flex-start;\n}\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"otherServicesSection": "otherServicesSection_7gfWX"
};
module.exports = ___CSS_LOADER_EXPORT___;
