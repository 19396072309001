import { Vue, Component } from 'nuxt-property-decorator'

import { CATEGORIES } from '~/constants/category'

@Component
export default class Categories extends Vue {
  Categories_getCategoryById(id: string): Category | null {
    return CATEGORIES.find(category => category.id === id) || null
  }
  Categories_getCategoryByV4Id(v4Id: number): Category | null {
    return CATEGORIES.find(category => category.v4Id === v4Id) || null
  }
}
