
import { Component, Getter, Action, Prop, mixins } from "nuxt-property-decorator"
import { extend, ValidationProvider } from "vee-validate"
import { required } from "vee-validate/dist/rules"
import Blocks from "~/mixins/blocks"
import { SET_PICKUP_PROPERTY_TYPE, SET_LISTING, SET_PICKUP_FLOOR } from "~/store/listing/actions"
import { GET_PICKUP_FLOOR, GET_PICKUP_PROPERTY_TYPE } from "~/store/listing/getters"
import InitialListing from "~/store/listing/state"
import { isHouse, isFlat, requiresFloor } from "~/services/public/Listing"
import {
  FLOORS,
  PROPERTY_TYPE_LIST_FLAT,
  PROPERTY_TYPE_LIST_HOUSE,
  PROPERTY_TYPE_LIST_MAIN
} from "~/constants/listing"
import {
  GET_FLOORS,
  GET_PROPERTY_TYPES,
  GET_PROPERTY_TYPES_FLAT,
  GET_PROPERTY_TYPES_HOUSE
} from "~/store/property/getters";

// Using ValidationProvider without validating anything is a workaround!
// ValidationProvider must be added as container for the main Sel items
// To allow subMenu Sel items using their own props
@Component({
  components: { ValidationProvider }
})
export default class PropertyPickupInput extends mixins(Blocks) {
  propertyTypesMain: any[] = PROPERTY_TYPE_LIST_MAIN
  propertyTypesHouse: any[] = PROPERTY_TYPE_LIST_HOUSE
  propertyTypesFlat: any[] = PROPERTY_TYPE_LIST_FLAT
  floors: any[] = FLOORS

  @Prop({ required: true, type: Object }) block: Record<string, any>
  @Prop({ default: false, type: Boolean }) virgin: boolean
  @Prop({ default: true, type: Boolean }) lazy: boolean
  @Prop({ default: false, type: Boolean }) validation: boolean

  @Getter(GET_PICKUP_PROPERTY_TYPE, { namespace: "listing" }) getPickupPropertyType: string | null
  @Getter(GET_PICKUP_FLOOR, { namespace: "listing" }) getPickupFloor: string | null
  @Getter(GET_PROPERTY_TYPES, { namespace: "property" }) getStorePropertyTypesMain: Function
  @Getter(GET_PROPERTY_TYPES_HOUSE, { namespace: "property" }) getStorePropertyTypesHouse: Function
  @Getter(GET_PROPERTY_TYPES_FLAT, { namespace: "property" }) getStorePropertyTypesFlat: Function
  @Getter(GET_FLOORS, { namespace: "property" }) getStoreFloors: Function

  @Action(SET_PICKUP_FLOOR, { namespace: "listing" }) setPickupFloor: Function
  @Action(SET_LISTING, { namespace: "listing" }) setListing: Function
  @Action(SET_PICKUP_PROPERTY_TYPE, { namespace: "listing" }) setPickupPropertyType: Function

  created() {
    if (this.validation) {
      extend("required", required)
    }
    this.setListing(InitialListing())
  }

  get getPropertyTypesMain() {
    return this.getStorePropertyTypesMain || this.propertyTypesMain
  }

  get getPropertyTypesHouse() {
    return this.getStorePropertyTypesHouse || this.propertyTypesHouse
  }

  get getPropertyTypesFlat() {
    return this.getStorePropertyTypesFlat || this.propertyTypesFlat
  }

  get getFloors() {
    return this.getStoreFloors || this.floors
  }

  get attributes() {
    return this.virgin ? null : this.Blocks_getAttributes(this.block)
  }

  get pickupPropertyType(): string | null {
    return this.getPickupPropertyType
  }

  set pickupPropertyType(value) {
    this.setPickupPropertyType(value)
  }

  get pickupFloor(): string | null {
    return this.getPickupFloor
  }

  set pickupFloor(value) {
    this.setPickupFloor(value)
  }

  isHouse(propertyType) {
    return isHouse(propertyType)
  }

  isFlat(propertyType) {
    return isFlat(propertyType)
  }

  requiresFloor(propertyType) {
    return requiresFloor(propertyType)
  }
}
