// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".trustPilot_O-\\+ZO{padding-left:15px;color:#909090}.trustPilot_O-\\+ZO h4{font-size:20px;font-weight:bold;margin-top:11px;margin-bottom:11px}.darkLogo_gXwBG,.lightLogo_Ggn3W{width:100%}.rating_K5zMJ{width:80%;margin:10px 0 0}@media(min-width: 768px){.rating_K5zMJ{margin:10px 0}}.dark_i03gU .lightLogo_Ggn3W{display:none}.light_TgXI7{color:#fff}.light_TgXI7 .darkLogo_gXwBG{display:none}.light_TgXI7 div a{color:#41a5dd}@media (min-width: 992px){.trustPilot_O-\\+ZO div{width:200px !important}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"trustPilot": "trustPilot_O-+ZO",
	"darkLogo": "darkLogo_gXwBG",
	"lightLogo": "lightLogo_Ggn3W",
	"rating": "rating_K5zMJ",
	"dark": "dark_i03gU",
	"light": "light_TgXI7"
};
module.exports = ___CSS_LOADER_EXPORT___;
