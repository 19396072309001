
import { Component } from "nuxt-property-decorator"
import TrustpilotBaseComponent from "~/mixins/trustpilotBase"

/* @ts-ignore TS1270 Component decorator cannot identify parent TrustpilotBaseComponent properly */
@Component
export default class TrustpilotComponent extends TrustpilotBaseComponent {
  get ratingString() {
    const rating = this.$t("trustpilot.rating").toString() + " Trustpilot"
    return rating
      .replace("RATING", `${this.trustpilotRating}`)
      .replace("REVIEWCOUNT", this.trustpilotCustomers.toString())
  }

  get localeLink() {
    const trustPilotLinks = {
      en: "https://uk.trustpilot.com/review/anyvan.com",
      es: "https://es.trustpilot.com/review/anyvan.es",
      fr: "https://fr.trustpilot.com/review/anyvan.fr",
      de: "https://de.trustpilot.com/review/anyvan.de",
      it: "https://it.trustpilot.com/review/anyvan.it"
    }
    if (!this.$i18n.locale) {
      return trustPilotLinks.en
    }
    return trustPilotLinks[this.$i18n.locale]
  }
}
