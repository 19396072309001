// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".internalReviews_wBhzb .container{display: block;overflow: hidden}.internalReviews_wBhzb p{margin-top: 20px}.wrapper_CYLCL{width: 100vw}.reviews_a7T9m{margin-top: 40px}.review_yX4Qq{border-radius: 9px;--tw-bg-opacity: 1;background-color: rgba(255, 255, 255, 1);background-color: rgba(255, 255, 255, var(--tw-bg-opacity));padding: 20px;--tw-text-opacity: 1;color: rgba(41, 41, 41, 1);color: rgba(41, 41, 41, var(--tw-text-opacity))}@media (min-width: 992px){.reviews_a7T9m{margin-left: 0;margin-right: 0;margin-top: 60px}.review_yX4Qq{padding: 50px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"internalReviews": "internalReviews_wBhzb",
	"wrapper": "wrapper_CYLCL",
	"reviews": "reviews_a7T9m",
	"review": "review_yX4Qq"
};
module.exports = ___CSS_LOADER_EXPORT___;
