
import { getBlockAttributes } from "~/utils/block"

export default {
  name: "AvLinkTable",

  props: {
    block: {
      type: Object, // Record<string, any>
      required: true
    },
    lazy: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {}
  },

  computed: {},

  methods: {
    getBlockAttributes(block) {
      return getBlockAttributes(block)
    }
  }
}
