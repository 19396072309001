
import Vue from "vue"

export default Vue.extend({
  name: "NavOurServices",
  data() {
    return {
      showMenu: false,
      showAllServices: false
    }
  },
  methods: {
    toggleMenu(override: null | boolean = null) {
      if (override !== null) {
        this.showMenu = override
      } else {
        this.showMenu = !this.showMenu
      }

      if (this.showMenu) {
        this.$emit("hide-other-menus")
      }

      // if the menu is now closed, close the all services menu
      if (!this.showMenu) {
        // delay by the amount of time it takes to animate the menu closed
        setTimeout(() => {
          this.toggleAllServices(false)
        }, 350)
      }
    },
    toggleAllServices(override: null | boolean = null) {
      if (override !== null) {
        this.showAllServices = override
      } else {
        this.showAllServices = !this.showAllServices
      }
    },
    toggleHelpCentre(override: null | boolean = null) {
      this.$emit("show-help-centre", override)
    },
    handleLoginbutton() {
      this.$emit("show-login-form")
      this.toggleMenu(false)
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    }
  }
})
