import { Vue, Component, Prop } from "nuxt-property-decorator"
import axios from "axios"

@Component
export default class TrustpilotBaseComponent extends Vue {
  @Prop({ type: Boolean, default: true }) lineBreaks: boolean
  @Prop({ type: Boolean, default: true }) readReviewsLink: boolean
  @Prop({ type: Number }) width: number
  @Prop({
    type: String,
    default: "dark",
    validator: (value: string) => ["dark", "light"].includes(value)
  })
  theme: string

  $style!: Object
  trustpilotRating: number = 0
  trustpilotCustomers: number = 0
  trustpilotCustomersFormatted: string = "0"

  async created() {
    const trustPilotConfig = this.$config.trustPilot
    let trustPilotId = trustPilotConfig.id
    let trustPilotAPIKey = trustPilotConfig.apiKey
    if (this.$i18n.locale !== "en") {
      const upperCaseLocale = this.$i18n.locale.toUpperCase()
      trustPilotId = trustPilotConfig[`id${upperCaseLocale}`]
      trustPilotAPIKey = trustPilotConfig[`apiKey${upperCaseLocale}`]
    }
    const trustpilotURL = `https://api.trustpilot.com/v1/business-units/${trustPilotId}`
    try {
      const axiosInstance = axios.create() as any
      const response = await axiosInstance.get(trustpilotURL, {
        params: {
          apikey: trustPilotAPIKey
        }
      })
      if (response.status === 200 && response.data) {
        this.trustpilotRating = response.data.score.trustScore
        this.trustpilotCustomers = response.data.numberOfReviews.total
        this.trustpilotCustomersFormatted = Number(this.trustpilotCustomers).toLocaleString()
      }
    } catch (err) {
      console.log("Trustpilot error", err)
    }
  }

  get css() {
    const css: string[] = []

    if (this.width) {
      css.push(`width: ${this.width}px`)
    }

    return css
  }

  get classes() {
    const classes: string[] = []
    classes.push(this.$style[this.theme])

    return classes
  }
}
