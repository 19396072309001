
import { Vue, Component, Prop, Getter } from "nuxt-property-decorator"
import { GET_PHONE_NUMBER_VALUE } from "~/store/phone-number/getters"

@Component
export default class PhoneNumberComponent extends Vue {
  @Prop({ type: Boolean, default: false }) inheritColor: boolean
  @Prop({ type: Boolean, default: false }) inheritFontSize: boolean
  @Prop({ type: Boolean, default: false }) showIcon: boolean
  @Prop({ type: Boolean, default: false }) showText: boolean
  @Prop({ type: Boolean, default: false }) showButton: boolean
  @Prop({ type: Boolean, default: false }) showButtonText: boolean
  @Prop({ type: Number, required: false }) fontSize: number
  @Prop({ type: Number, required: false }) iconSize: number
  @Prop({ type: String, default: "Call us" }) buttonText: string
  @Prop({ type: String, default: "", required: false }) linkClass: string
  @Prop({ type: Array, default: () => ["custom", "telephone-circle-1"] }) icon: string[]
  @Prop({ type: Function, default: () => {} }) handleClick: any
  @Getter(GET_PHONE_NUMBER_VALUE, { namespace: "phone-number" })
  getPhoneNumberValue: PhoneNumberPair

  get fontStyle() {
    const styles: string[] = []

    if (this.fontSize) {
      styles.push(`font-size: ${this.fontSize}px`)
    }

    return styles
  }
}
