// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".av-buttons .btnPhone_AaSnm{align-self: center}.av-buttons .btnPhone_AaSnm a{display: inline-block;height: auto;border-style: none}@media(min-width: 768px){.av-buttons .btnPhone_AaSnm{display: none}}.av-buttons .fullBtnPhone_bsGfg{margin-top: 30px;display: none;align-self: center}.av-buttons .fullBtnPhone_bsGfg a{height: auto;align-items: center;justify-content: center;gap: 10px;border-style: none}@media(min-width: 768px){.av-buttons .fullBtnPhone_bsGfg{display: flex}}.av-buttons .phoneFullWidth_e89rn{width: 100%}@media (min-width: 768px){.av-buttons .phoneFullWidth_e89rn{width: auto}.layout-container .removals-hub-hero .av-buttons.phone-number-buttons,.layout-container .cover-text-cta .av-buttons.phone-number-buttons{width: 100%;flex-wrap: wrap;-moz-column-gap: 30px;column-gap: 30px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnPhone": "btnPhone_AaSnm",
	"fullBtnPhone": "fullBtnPhone_bsGfg",
	"phoneFullWidth": "phoneFullWidth_e89rn"
};
module.exports = ___CSS_LOADER_EXPORT___;
