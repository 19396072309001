type Size = "xs" | "s" | "xm" | "m" | "l" | "xl"

export const getSizeClasses = (size: Size) => {
  const classes: string[] = []
  switch (size) {
    case "xs":
      classes.push("xs-size")
      classes.push("h-32")
      classes.push("text-sm")
      break
    case "s":
      classes.push("h-40")
      classes.push("text-sm")
      break
    case "xm":
      classes.push("h-40")
      classes.push("text-base")
      break
    case "m":
      classes.push("h-48")
      classes.push("text-lg")
      break
    case "l":
      classes.push("h-56")
      classes.push("text-xl")
      break
    case "xl":
      classes.push("h-60")
      classes.push("text-lg")
      break
  }
  return classes
}

export function getSelectSizeClasses(size: Size) {
  const classes: string[] = []
  switch (size) {
    case "xs":
      classes.push("h-auto")
      classes.push("w-full")
      classes.push("size-extra-small")
      classes.push("text-sm")
      break
    case "s":
      classes.push("h-auto")
      classes.push("w-full")
      classes.push("size-small")
      classes.push("text-sm")
      break
    case "xm":
    case "m":
      classes.push("h-auto")
      classes.push("w-full")
      classes.push("size-medium")
      classes.push("text-lg")
      break
    case "l":
      classes.push("h-auto")
      classes.push("w-full")
      classes.push("size-large")
      classes.push("text-xl")
      break
    case "xl":
      classes.push("h-auto")
      classes.push("w-full")
      classes.push("size-xl")
      classes.push("text-lg")
      break
  }
  return classes
}
