
import Vue from "vue"
import { mapGetters } from "vuex"
import { SnowplowService } from "~/services/public/Snowplow"
import { GET_PHONE_NUMBER_VALUE } from "~/store/phone-number/getters"

export default Vue.extend({
  name: "NavCallUs",
  data() {
    return {
      snowplowService: null as SnowplowService | null
    }
  },
  computed: {
    ...mapGetters("phone-number", {
      phone_getPhoneNumberValue: GET_PHONE_NUMBER_VALUE
    })
  },
  mounted() {
    this.snowplowService = new SnowplowService()
  },
  methods: {
    handleCalUsClick(phoneNumber: string, extension?: string) {
      this.snowplowService?.trackCallUs({
        phoneNumber,
        label: "navigation_button",
        action: "click",
        extension
      })
      this.$emit("hide-other-menus")
    }
  }
})
