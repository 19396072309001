
import { Vue, Component, Prop } from 'nuxt-property-decorator'
import axios from 'axios'
import { formatDistance } from 'date-fns'

@Component
export default class TrustpilotReviewsComponent extends Vue {
  @Prop({ required: true, type: Object }) block: Record<string, any>

  reviews: Record<string, any>[] = []

  trustpilotTotalReviews: string = '0'
  trustpilotExcellentReviewsRounded: string = '0'
  trustpilotRating: number = 0

  async created() {
    const trustPilotConfig = this.$config.trustPilot
    const trustpilotURL = `https://api.trustpilot.com/v1/business-units/${trustPilotConfig.id}`
    const trustpilotURLReviews = `https://api.trustpilot.com/v1/business-units/${trustPilotConfig.id}/reviews`

    try {
      const axiosInstance = axios.create() as any
      const response = await axiosInstance.get(trustpilotURL, {
        params: {
          apikey: trustPilotConfig.apiKey,
        },
      })

      if (response.status === 200 && response.data) {
        this.trustpilotRating = response.data.score.trustScore
        this.trustpilotTotalReviews = Number(
          response.data.numberOfReviews.total
        ).toLocaleString()
        this.trustpilotExcellentReviewsRounded = Number(
          Math.floor(response.data.numberOfReviews.fiveStars / 1000) * 1000
        ).toLocaleString()
      }

      const responseReviews = await axiosInstance.get(trustpilotURLReviews, {
        params: {
          apikey: trustPilotConfig.apiKey,
          stars: [5],
        },
      })

      if (responseReviews.status === 200 && responseReviews.data) {
        this.reviews = responseReviews.data.reviews
      }
    } catch (err) {
      console.log('Trustpilot error', err)
    }
  }

  reviewHref(href) {
    return href.replace(
      'api.trustpilot.com/v1/reviews/',
      'www.trustpilot.com/reviews/'
    )
  }

  formatDistance(date) {
    return formatDistance(new Date(), new Date(date))
  }

  get title() {
    return this.block.innerBlocks[0].innerBlocks[0].innerBlocks[0].innerHtml.replace(
      new RegExp('<h2[^>]*>+(.*)<\/h2>'),
      '$1'
    )
  }
}
