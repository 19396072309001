
import { Vue, Component, Prop, Emit, Getter } from "nuxt-property-decorator"
import { GET_FW_TOKEN, GET_FW_TOKEN_TIMESTAMP } from "~/store/auth/getters"

@Component
export default class LoginForm extends Vue {
  @Prop({ type: Boolean, default: false }) value: boolean

  @Getter(GET_FW_TOKEN, { namespace: "auth" }) nonce: string
  @Getter(GET_FW_TOKEN_TIMESTAMP, { namespace: "auth" }) nonceTimestamp: number

  @Emit("input")
  closeForm() {
    return false
  }
}
