// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".accordion_Oh5lD{border-radius:5px;border-width:1px;--tw-border-opacity:1;border-color:rgba(216, 216, 216, 1);border-color:rgba(216, 216, 216, var(--tw-border-opacity));--tw-bg-opacity:1;background-color:rgba(255, 255, 255, 1);background-color:rgba(255, 255, 255, var(--tw-bg-opacity))}.accordion_Oh5lD .accordionTitle_Q-NGj{display:inline-flex;width:100%;cursor:pointer;align-items:center;justify-content:space-between;gap:30px;padding-top:12px;padding-bottom:12px;padding-left:20px;padding-right:15px}.accordion_Oh5lD .accordionTitle_Q-NGj h5{font-weight:700;font-size:1.000em}.accordion_Oh5lD:not(.stateActive_QX6W2) .accordionTitle_Q-NGj h5{font-weight:400}.accordion_Oh5lD .icon_WW4rY{position:relative;margin-top:6px;margin-bottom:6px;transform:rotate(90deg);transition:all cubic-bezier(0.4, 0, 0.2, 1) .25s}.accordion_Oh5lD.stateActive_QX6W2 .accordionTitle_Q-NGj .icon_WW4rY{transform:rotate(-90deg)}.accordion_Oh5lD.stateActive_QX6W2 .panelCollapse_mJ2To{padding-top:20px;padding-bottom:20px}.accordion_Oh5lD .panelCollapse_mJ2To{padding-left:20px;padding-right:20px}@media (min-width: 768px){.accordion_Oh5lD .accordionTitle_Q-NGj{gap:60px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accordion": "accordion_Oh5lD",
	"accordionTitle": "accordionTitle_Q-NGj",
	"stateActive": "stateActive_QX6W2",
	"icon": "icon_WW4rY",
	"panelCollapse": "panelCollapse_mJ2To"
};
module.exports = ___CSS_LOADER_EXPORT___;
