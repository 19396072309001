// eslint-disable-next-line
import { Icon } from "@yzfe/vue-svgicon"

const icons: Icon[] = []

// @ts-ignore -- Property 'context' does not exist on type 'NodeRequire'.
const req = require.context('@icon', true, /\.svg/)
req.keys().forEach((key) => {
  icons.push(req(key))
})

export default icons
