
import { Component, Prop, mixins } from "nuxt-property-decorator"
import Blocks from "~/mixins/blocks"

@Component
export default class PageTileNavigationComponent extends mixins(Blocks) {
  @Prop({ required: true, type: Object }) block: Block
  @Prop({ required: true, type: String }) blockId: string
  @Prop({ required: false, type: Boolean }) fullImage: boolean

  private id!: string
  private static componentId = 0
  activeSlide: number = 0
  scrollLimit: number = 200
  minimumSlides: number = 1
  nextSlidesKey: number = 0
  slideWidth: number = 468 // basic width with gap
  slides: Block[] = []

  created() {
    if (this.blockId) {
      this.id = this.blockId
    } else {
      PageTileNavigationComponent.componentId += 1
      this.id = `nav-${PageTileNavigationComponent.componentId}`
    }
    if (this.fullImage) {
      this.slideWidth = 392 // full image width with gap
    }
  }

  mounted() {
    const slidesWrapper = this.Blocks_findBlockByClass(this.block, "av-carousel-tiles")

    if (slidesWrapper && slidesWrapper.innerBlocks.length) {
      this.slides = slidesWrapper.innerBlocks
      this.nextSlidesKey = 0
    }
  }

  get offSet() {
    return { right: this.slideWidth * this.activeSlide + "px" }
  }

  cloneOriginalSlides() {
    let slidesCount = this.slides.length
    const slidesClone: Block[] = [...this.slides]

    while (slidesCount < this.minimumSlides) {
      const nextSlideId = slidesCount - slidesClone.length
      this.slides.push(slidesClone[nextSlideId])
      slidesCount++
    }
    this.nextSlidesKey = this.minimumSlides - slidesClone.length
  }

  scrollLeftMobile() {
    if (this.activeSlide > 0) {
      this.activeSlide--
      setTimeout(() => {
        // @ts-ignore
        this.$scrollTo(`#${this.id}-${this.activeSlide}`, 500, {
          container: `#${this.id}`,
          x: true,
          y: false
        })
      }, 500)
    }
  }

  scrollRightMobile() {
    if (this.activeSlide < this.slides.length - 1) {
      this.activeSlide++
      setTimeout(() => {
        // @ts-ignore
        this.$scrollTo(`#${this.id}-${this.activeSlide}`, 500, {
          container: `#${this.id}`,
          x: true,
          y: false
        })
      }, 500)
    }
  }

  scrollRight() {
    if (this.activeSlide < this.scrollLimit) {
      this.slides.push(this.slides[this.activeSlide + this.nextSlidesKey])
      this.activeSlide++
    }
  }
}
