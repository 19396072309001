
  export default {
    name: 'IconBase',
    props: {
      viewBox: {
        type: String,
        default: '0 0 30 30'
      }
    }
  };
