// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".trustPilot_x92LW{padding-left:15px;color:#909090}.trustPilot_x92LW h4{font-size:20px;font-weight:bold;margin-top:11px;margin-bottom:11px}.darkLogo_QyA\\+I,.lightLogo_IXlnp{width:100%}.rating_Z-\\+L8{width:80%;margin:10px 0 0}@media(min-width: 768px){.rating_Z-\\+L8{margin:10px 0}}.dark_40skS .lightLogo_IXlnp{display:none}.light_xvjAU{color:#fff}.light_xvjAU .darkLogo_QyA\\+I{display:none}.light_xvjAU div a{color:#41a5dd}@media (min-width: 992px){.trustPilot_x92LW div{width:200px !important}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"trustPilot": "trustPilot_x92LW",
	"darkLogo": "darkLogo_QyA+I",
	"lightLogo": "lightLogo_IXlnp",
	"rating": "rating_Z-+L8",
	"dark": "dark_40skS",
	"light": "light_xvjAU"
};
module.exports = ___CSS_LOADER_EXPORT___;
