
import Vue from "vue"
import { extend, ValidationObserver, ValidationProvider } from "vee-validate"
import { email, required } from "vee-validate/dist/rules"
import { ListingService } from "~/services/public/Listing"

export default Vue.extend({
  name: "RetrieveQuoteModal",
  components: { ValidationObserver, ValidationProvider },
  props: {},

  created() {
    extend("email", email)
    extend("required", required)
  },

  data() {
    return {
      listingService: new ListingService(this.$axios, this.$config),
      email: "",
      emailFormSubmit: null as boolean | null,
      submitted: false
    }
  },

  computed: {},

  methods: {
    async submitEmailForm() {
      try {
        // @ts-ignore: Property 'validate' does not exist on type CombinedVueInstance
        const isValid = await this.$refs.observer?.validate()
        if (!isValid) {
          this.submitted = true
          return
        }
        const response = await this.listingService.postEmail({ email: this.email })
        this.emailFormSubmit = response?.data.success
      } catch (err) {
        this.emailFormSubmit = false
        console.error("Email sending error", err)
      } finally {
        this.submitted = true
      }
    },

    getCategoriesLink() {
      return this.listingService.getCategoriesLink()
    }
  }
})
