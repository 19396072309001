/**
 * Converting Google PlaceResult object to AnyVan Address
 *
 * @param place Google result
 */
export function placeToAddress(place): Address {
  if (typeof place.address_components === "undefined") {
    throw new TypeError("Invalid place object")
  }

  const address: Address = {
    postcode: "",
    postcode_short: "",
    addressLine1: "",
    addressLine2: null,
    city: null,
    region: null,
    country: "",
    coordinates: {
      lat: 0,
      lng: 0
    },
    place
  }

  // eslint-disable-next-line no-undef
  let tmp: google.maps.GeocoderAddressComponent | undefined

  tmp = place.address_components.find(item => item.types.includes("postal_code"))
  if (typeof tmp !== "undefined") {
    address.postcode = tmp.long_name
  }

  tmp = place.address_components.find(item => item.types.includes("route"))
  if (typeof tmp !== "undefined") {
    address.addressLine1 = tmp.long_name
  }

  tmp = place.address_components.find(
    item => item.types.includes("postal_town") || item.types.includes("locality")
  )
  if (typeof tmp !== "undefined") {
    address.city = tmp.long_name
  }

  tmp = place.address_components.find(item => item.types.includes("country"))
  if (typeof tmp !== "undefined") {
    address.country = tmp.short_name
  }

  if (typeof place.geometry !== "undefined") {
    address.coordinates = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng()
    }
  }

  return address
}
