
import Vue from "vue"
import VueScrollTo from "vue-scrollto"
import { mapGetters } from "vuex"
import eventBus from "~/scripts/bus"
import { DESKTOP_SUMMARY_ITEMS_COUNT_TO_VIEW } from "~/constants/item"
import { SnowplowService } from "~/services/public/Snowplow"
import { GET_INVENTORY, GET_IS_SMALL_OR_MEDIUM } from "~/store/pages/getters"
import { GET_UNIQUE_ITEMS_COUNT } from "~/store/listing/getters"
import { ADD_ITEM, UPDATE_ITEM } from "~/store/listing/actions"
import { SET_INVENTORY_DESKTOP } from "~/store/pages/actions"
Vue.use(VueScrollTo)

export default Vue.extend({
  name: "LocationFormExtension",

  props: {
    block: {
      required: true,
      type: Object
    },
    blockId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      eventBus,
      originalItem: null as Item | null,
      formSubmitted: false,
      openEditModal: false,
      snowplowService: new SnowplowService(),
      containerStyle: "",
      transitionInProgress: true
    }
  },
  computed: {
    ...mapGetters("pages", {
      extension_getInventory: GET_INVENTORY,
      extension_getIsSmallOrMedium: GET_IS_SMALL_OR_MEDIUM
    }),
    ...mapGetters("listing", {
      extension_getUniqueItemsCount: GET_UNIQUE_ITEMS_COUNT
    })
  },
  watch: {
    extension_getUniqueItemsCount(newValue, oldValue) {
      if (oldValue === null) {
        this.$nextTick(() => {
          this.containerStyle =
            "transform: translate3d(0, 0, 0); transition: transform 0.4s ease-out;"
        })
        setTimeout(() => {
          this.transitionInProgress = false
        }, 500)
      }
      if (newValue > DESKTOP_SUMMARY_ITEMS_COUNT_TO_VIEW && newValue > oldValue) {
        this.$nextTick(() => {
          this.$scrollTo(`#item-${newValue - 1}`, 1000, {
            container: "#summary-items",
            x: false,
            y: true
          })
        })
      }
    }
  },
  created() {
    this.$store.dispatch("pages/" + SET_INVENTORY_DESKTOP, true)
  },
  beforeMount() {
    eventBus.$on("open-create-item", this.openCreateItem)
  },
  methods: {
    submitForm() {
      eventBus.$emit("submit-location-form")
    },
    async submitModal(item: Item) {
      let success = false
      if (this.originalItem && this.originalItem.fake_id) {
        success = await this.$store.dispatch("listing/" + UPDATE_ITEM, item)
      } else {
        success = await this.$store.dispatch("listing/" + ADD_ITEM, item)
      }
      if (success) {
        this.closeEditItem()
      }
    },
    openCreateItem(item: Item | null = null) {
      this.openEditModal = true
      this.originalItem = item
      if (item) {
        this.snowplowService.trackCreateItemOpen(item.name, item.source)
      } else {
        this.snowplowService.trackCreateItemOpen("", "other_custom_item")
      }
    },
    openEditItem(item: Item | null = null) {
      this.openEditModal = true
      this.originalItem = item
      if (item) {
        this.snowplowService.trackEditItemOpen(item.item_id || item.name, item.quantity)
      } else {
        this.snowplowService.trackEditItemOpen("", 0)
      }
    },
    closeEditItem() {
      this.openEditModal = false
      this.originalItem = null
    }
  }
})
