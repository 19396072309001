
import { getBlockAttributes } from "~/utils/block"

export default {
  name: "ImageText",

  props: {
    block: {
      type: Object,
      required: true
    },
    lazy: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    filteredInnerBlocks() {
      // @ts-ignore
      return this.block.innerBlocks.slice(2)
    }
  },
  methods: {
    getBlockAttributes(block) {
      return getBlockAttributes(block)
    }
  }
}
