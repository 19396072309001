// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.overlayImage_ValIO {\n\n  position: relative;\n\n  margin-bottom: 32px\n}\n.overlayImage_ValIO figure {\n\n  border-radius: 16px\n}\n.overlayImage_ValIO .overlayBlock_5TwqG {\n\n  position: absolute;\n\n  left: 50%;\n\n  display: flex;\n\n  flex-direction: column;\n\n  align-items: flex-start;\n\n  gap: 10px;\n\n  align-self: stretch;\n\n  padding-left: 0;\n\n  --tw-translate-x: -50%;\n\n  --tw-translate-y: -50%;\n\n  transform: translate(-50%, -50%) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));\n\n  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))\n}\n.overlayImage_ValIO .spacerBlock_ysN49 {\n\n  height: 50px\n}\n@media (min-width: 768px) {\n.overlayImage_ValIO {\n\n    margin-bottom: 48px\n}\n}\n@media (min-width: 1200px) {\n.overlayImage_ValIO {\n\n    margin-bottom: 64px\n}\n.overlayImage_ValIO figure {\n\n    border-radius: 32px\n}\n.overlayImage_ValIO .overlayBlock_5TwqG {\n\n    left: 40%\n}\n.overlayImage_ValIO .spacerBlock_ysN49 {\n\n    height: 0\n}\n}\n@media (min-width: 1512px) {\n.overlayImage_ValIO .overlayBlock_5TwqG {\n\n    left: 30%\n}\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlayImage": "overlayImage_ValIO",
	"overlayBlock": "overlayBlock_5TwqG",
	"spacerBlock": "spacerBlock_ysN49"
};
module.exports = ___CSS_LOADER_EXPORT___;
