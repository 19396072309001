// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.av-icon-table {\n  margin-top: 32px;\n}\n.valueAddSection_E5jMs {\n  display: flex;\n  flex-direction: column;\n}\n.valueAddSection_E5jMs > *:nth-child(2) {\n  order: 3;\n  padding-top: 16px;\n}\n@media (min-width: 768px) {\n.valueAddSection_E5jMs {\n    display: grid;\n    grid-template-rows: auto auto;\n    grid-template-columns: 5fr 1fr 6fr;\n    grid-template-areas:\n      \"image gap heading\"\n      \"image gap table\";\n}\n.valueAddSection_E5jMs > *:nth-child(1) {\n      grid-area: heading;\n}\n.valueAddSection_E5jMs > *:nth-child(2) {\n      grid-area: image;\n}\n.valueAddSection_E5jMs > *:nth-child(3) {\n      grid-area: table;\n}\n.valueAddSection_E5jMs.value-add-reverse {\n      grid-template-columns: 6fr 1fr 5fr;\n      grid-template-areas:\n        \"heading gap image\"\n        \"table gap image\";\n}\n.valueAddSection_E5jMs .av-heading {\n    margin-bottom: 0;\n    align-self: center;\n    text-align: left;\n}\n.valueAddSection_E5jMs .av-icon-table {\n    justify-content: flex-start;\n}\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"valueAddSection": "valueAddSection_E5jMs"
};
module.exports = ___CSS_LOADER_EXPORT___;
