import { render, staticRenderFns } from "./TheHeaderContainer.vue?vue&type=template&id=526dee66&lang=pug"
import script from "./TheHeaderContainer.vue?vue&type=script&lang=ts"
export * from "./TheHeaderContainer.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {TheRebrandHeader: require('/app/components/rebrand-header/TheRebrandHeader.vue').default,LocalisedTheHeaderDE: require('/app/components/localised/TheHeaderDE.vue').default,LocalisedTheHeaderES: require('/app/components/localised/TheHeaderES.vue').default,LocalisedTheHeaderFR: require('/app/components/localised/TheHeaderFR.vue').default,LocalisedTheHeaderIE: require('/app/components/localised/TheHeaderIE.vue').default,LocalisedTheHeaderIT: require('/app/components/localised/TheHeaderIT.vue').default})
