// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.av-image-text > .content {\n  display: block\n}\n.imageContainer_R7PRn {\n  display: grid;\n  align-items: center;\n  justify-items: center;\n  place-items: center\n}\n.heading_aeaWf.av-heading {\n  margin-bottom: 40px;\n  font-size: 2.5rem\n}\n@media (min-width: 992px) {\n.imageContainer_R7PRn {\n    float: left;\n    margin-right: 130px\n}\n.heading_aeaWf.av-heading {\n    font-size: 2.75rem\n}\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"imageContainer": "imageContainer_R7PRn",
	"heading": "heading_aeaWf"
};
module.exports = ___CSS_LOADER_EXPORT___;
