
import Vue from "vue"
import { findBlockByAttribute } from "~/utils/block"

interface Attribute {
  name: string
  value: string
}
export default Vue.extend({
  name: "MediaTextResponsive",
  props: {
    block: {
      required: true,
      type: Object as () => Block
    },
    lazy: {
      default: true,
      type: Boolean
    }
  },
  computed: {
    imageBlock() {
      return this.block.innerBlocks[0]
    },
    headingBlock() {
      return this.block.innerBlocks[1]
    },
    parentElementName() {
      // Because MediaTextResponsive is used several times on one page, hydration errors can occur when for-loop keys are reused.
      // A nice solution for this and for editors it to give the parent Group block a name that's unique on that page.
      const groupNameAttribute: string = (
        this.block.attributes.find(attr => attr.name === "metadata") || {
          value: '{\\"name\\":\\"unnamed-group\\"}'
        }
      ).value
      return JSON.parse(groupNameAttribute).name.replace(/\s+/g, "")
    },
    desktopImageAlignedRight() {
      const responsiveImageSetBlock = findBlockByAttribute(
        this.block,
        "className",
        "av-responsive-image-set"
      )
      if (responsiveImageSetBlock) {
        return !!findBlockByAttribute(responsiveImageSetBlock, "align", "right")
      }
      return false
    }
  }
})
