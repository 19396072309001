
import Vue from "vue"
import { mapGetters } from "vuex"
import { GET_LOCATION_INFO } from "~/store/location/getters"

export default Vue.extend({
  name: "AvLocalSummary",
  props: {
    block: {
      type: Object as () => Block,
      required: true
    }
  },
  computed: {
    ...mapGetters("location", {
      locationData: GET_LOCATION_INFO
    }),
    uspList() {
      return [
        { emoji: "💰", text: this.locationData.summaryUsp1 },
        { emoji: "🤝", text: this.locationData.summaryUsp2 },
        { emoji: "🏠", text: this.locationData.summaryUsp3 }
      ]
    }
  }
})
