
import { Vue, Component, Prop, mixins } from "nuxt-property-decorator"
import { getMediaById } from "~/services/public/Pages"
import ImageUrlS3 from "~/mixins/imageUrlS3"

@Component
export default class OptimizedImageComponent extends mixins(ImageUrlS3) {
  @Prop({ default: "", type: String }) blockId: string
  @Prop({ default: "", type: String }) media: string
  @Prop({ required: true }) imageData: Record<string, any>
  @Prop({ default: false, type: Boolean }) lazy: boolean
  @Prop({ default: "DATABASE_ID", type: String }) idType: string
  @Prop({ type: String, default: null }) size: string
  @Prop({ type: Object as () => Block }) block: Block

  image: Record<string, any> = {
    width: "auto",
    height: "auto"
  }

  get extractAltText(): string {
    if (this.block && this.block.innerHtml) {
      const matchAltFromInnerHTMLRegex = new RegExp(/(?<=alt=")([^"]*?)(?=")/g)
      const match = this.block.innerHtml.match(matchAltFromInnerHTMLRegex)
      if (match) {
        return match[0]
      }
    }
    return ""
  }

  get imageAttrs() {
    const attrs: any = {
      alt: this.image.altText || "image-" + this.imageData.id,
      class: {
        // @ts-ignore -- Property '$style' does not exist
        [this.$style.image]: true,
        "fixed-size": this.imageData.width || this.imageData.height
      },
      style: "--image-width:" + this.image.width + "; --image-height:" + this.image.height + ";"
    }

    if (this.image.width !== "auto") {
      attrs.width = this.image.width
    }
    if (this.image.height !== "auto") {
      attrs.height = this.image.height
    }

    if (!this.lazy && this.blockId === "block-no-0") {
      attrs.fetchpriority = "high"
    }

    return attrs
  }

  head() {
    if (!this.lazy && this.blockId === "block-no-0") {
      if (this.media) {
        return {
          link: [
            {
              rel: "preload",
              as: "image",
              imagesrcset: this.image.sourceUrl
                ? this.image.sourceUrl
                : this.replaceUrlS3(this.imageData.url),
              media: this.media
            }
          ]
        }
      }

      return {
        link: [
          {
            rel: "preload",
            as: "image",
            href: this.image.sourceUrl
              ? this.image.sourceUrl
              : this.replaceUrlS3(this.imageData.url)
          }
        ]
      }
    }
  }

  async fetch() {
    if (this.imageData.id && process.server) {
      try {
        const response = await getMediaById(this.$config, this.imageData.id, this.idType)

        if (response.data && response.data.mediaItem) {
          const media = response.data.mediaItem
          const selectedSize =
            media.mediaDetails.sizes && this.size && this.size != "full"
              ? media.mediaDetails.sizes.find(size => size.name === this.size)
              : null

          if (selectedSize) {
            this.image.width = selectedSize.width
            this.image.height = selectedSize.height
            this.image.sourceUrl = this.replaceUrlS3(selectedSize.sourceUrl)
            this.image.srcSet = null
            this.image.altText = media.altText
          } else {
            this.image = media
            this.image.srcSet = this.replaceUrlS3(this.image.srcSet)

            if (media.mediaDetails.sizes && media.mediaDetails.sizes.length > 1) {
              media.mediaDetails.sizes.push({
                width: this.image.mediaDetails.width,
                height: this.image.mediaDetails.height,
                sourceUrl: this.image.sourceUrl
              })
            } else {
              this.image.width = this.image.mediaDetails.width
              this.image.height = this.image.mediaDetails.height
              this.image.sourceUrl = this.replaceUrlS3(media.sourceUrl)
            }
          }
        }
      } catch (err) {
        console.log("Media error", err)
      }
    }

    if (this.imageData.width || this.imageData.height) {
      this.image.width = this.imageData.width
      this.image.height = this.imageData.height
    }
  }
}
