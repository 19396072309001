import {Component, Getter, mixins, Prop} from "nuxt-property-decorator"
import { getYear } from "date-fns"
import Breakpoints from "~/mixins/breakpoints"
import {GET_USER} from "~/store/auth/getters";

@Component
export default class FooterComponent extends mixins(Breakpoints) {
  currentYear: number = getYear(new Date())

  @Prop({ type: String }) phone: string
  @Prop({ type: String }) email: string
  @Prop({ type: Boolean, default: false }) isRebrand: boolean
  @Getter(GET_USER, { namespace: "auth" }) user?: any

  get emailHref() {
    return `mailto:${this.email}`
  }
}
