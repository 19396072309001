import { Vue, Component } from "nuxt-property-decorator"
import { getBlockAttribute, getBlockAttributes } from "~/utils/block"

@Component
export default class Blocks extends Vue {
  Blocks_patternCheck(attributes: BlockAttribute[], pattern) {
    return this.Blocks_getClassName(attributes).includes(pattern)
  }

  Blocks_getClassName(attributes: BlockAttribute[]) {
    if (!attributes) {
      return ""
    }

    return this.Blocks_getAttribute(attributes, "className")
  }

  Blocks_findBlockByClass(block: Block, className: string) {
    if (!block.attributes || !className) {
      return null
    }

    if (this.Blocks_getClassName(block.attributes).includes(className)) {
      return block
    } else if (block.innerBlocks) {
      for (let i = 0; i < block.innerBlocks.length; i++) {
        const find = this.Blocks_findBlockByClass(block.innerBlocks[i], className)

        if (find) {
          return find
        }
      }
    }
  }

  Blocks_getAttribute(attributes: BlockAttribute[], attributeName) {
    return getBlockAttribute(attributes, attributeName)
  }

  Blocks_getAttributes(block) {
    return getBlockAttributes(block)
  }
}
