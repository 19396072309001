
import Vue from "vue"

export default Vue.extend({
  name: "ResponsiveImageSet",
  props: {
    block: {
      required: true,
      type: Object as () => Block
    },
    blockId: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      screenMd: 768,
      screenLg: 992
    }
  },
  computed: {
    imageCaption() {
      const regex = /<figcaption[^>]*>([^"]+)<\/figcaption>/
      const caption = regex.exec(
        this.block.innerBlocks[this.block.innerBlocks.length - 1].innerHtml
      )

      if (caption) {
        return caption[1]
      }

      return null
    }
  },
  methods: {
    sourceMedia(key: number) {
      if (key === 0) {
        return `(min-width: ${this.screenLg}px)`
      } else if (key === 1) {
        return `(min-width: ${this.screenMd}px) and (max-width: ${this.screenLg - 1}px)`
      }

      return ""
    },
    imageMedia(imageCount: number) {
      if (imageCount === 3) {
        return `(max-width: ${this.screenMd - 1}px)`
      } else if (imageCount === 2) {
        return `(max-width: ${this.screenLg - 1}px)`
      }

      return ""
    }
  }
})
