
import { Component, Prop, mixins, Getter, Action } from "nuxt-property-decorator"
import { GET_NODES } from "~/store/pages/getters"
import { LOAD_PAGES } from "~/store/pages/actions"
import Blocks from "~/mixins/blocks"
import ImageUrlS3 from "~/mixins/imageUrlS3"
import { PageFilterService } from "~/services/public/PageFilter"
import { TagsService } from "~/services/public/Tags"

@Component({
  fetchKey: "post-tiles"
})
export default class PostTilesComponent extends mixins(Blocks, ImageUrlS3) {
  @Prop({ required: true, type: Object as () => Block }) block: Block

  // probably later we can add a computed property for this using the 'skip-publish' tag
  @Getter(GET_NODES, { namespace: "pages" }) getNodes: Page[] | null

  @Action(LOAD_PAGES, { namespace: "pages" }) loadPages: Function

  tiles: Page[] = []

  stripTrailingSlash(uri) {
    return uri.replace(/\/$/, "")
  }

  async fetch() {
    if (process.server) {
      const query = JSON.parse(this.Blocks_getAttribute(this.block.attributes, "query"))

      const where = {
        categoryIn: query.taxQuery.category.join()
      }

      const tagsService = new TagsService(this.$config)
      const pageFilterService = new PageFilterService(tagsService)
      const tagsNotIn = await pageFilterService.getSkipPublishTags()

      if (tagsNotIn.length) {
        where["tagNotIn"] = tagsNotIn
      }

      await this.loadPages({
        config: this.$config,
        options: {
          after: "",
          first: 50,
          where: where
        }
      })
    }
  }
}
