
import Vue from "vue"
import { replaceUrlS3Helper } from "~/scripts/useful-functions"

export default Vue.extend({
  name: "ComparisonTable",
  props: {
    block: {
      required: true,
      type: Object as () => Block
    }
  },
  methods: {
    getTableWithReplacedImage(innerHtml: string): string {
      return replaceUrlS3Helper(innerHtml, this.$config.cdnUrl, this.$config.graphqlEndpoint)
    }
  }
})
