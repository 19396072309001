// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".postTiles_EGLDW{margin-bottom:25px;display:grid;grid-gap:25px;gap:25px}.postTiles_EGLDW .tile_ps0pi{height:220px;position:relative}.postTiles_EGLDW .tile_ps0pi img{position:absolute;top:0;right:0;bottom:0;left:0;height:100%;width:100%;-o-object-fit:cover;object-fit:cover}.postTiles_EGLDW .tile_ps0pi p{margin:0}.postTiles_EGLDW .tile_ps0pi p a{position:absolute;box-sizing:content-box;display:flex;height:100%;align-items:center;justify-content:center;text-align:center;font-size:1.625rem;line-height:2rem;--tw-text-opacity:1;color:rgba(255, 255, 255, 1);color:rgba(255, 255, 255, var(--tw-text-opacity));text-decoration-line:none;padding:0 7%;width:86%;font-weight:300;background-color:rgba(0,0,0,.4);text-shadow:0 0 5px #000;transition:background-color .3s ease-in-out}.postTiles_EGLDW .tile_ps0pi p a:hover{background-color:rgba(0,0,0,.3019607843)}@media (min-width: 768px){.postTiles_EGLDW{grid-template-columns:repeat(3, 1fr)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"postTiles": "postTiles_EGLDW",
	"tile": "tile_ps0pi"
};
module.exports = ___CSS_LOADER_EXPORT___;
