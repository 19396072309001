
export default {
  name: "AvCheckList",

  props: {
    block: {
      type: Object, // Record<string, any>
      required: true
    },
    lazy: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    getContent(innerHtml) {
      return innerHtml.replace(/(<([^>]+)>)/gi, "")
    }
  }
}
