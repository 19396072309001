
export default {
  name: "AvailabilityRemark",

  props: {
    block: {
      type: Object, // Record<string, any>
      required: true
    },
    virgin: {
      type: Boolean,
      default: false
    },
    lazy: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      output: "",
      day: 0,
      hour: 0
    }
  },

  computed: {},

  created() {
    this.getNow()
    let today = ""
    if (this.hour < 19) {
      today = "Today, "
    }

    if (this.day <= 5) {
      this.output = today + "Tomorrow and all week!"
    } else {
      this.output = today + "Tomorrow and all of next week!"
    }
  },

  methods: {
    getNow() {
      const now = new Date()
      this.day = now.getDay()
      this.hour = now.getHours()
    }
  }
}
