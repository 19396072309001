// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.cardContent_F1ziB {\n  transition: transform 0.3s ease;\n  transform: translateY(\n    calc(100% - 2rem - 16px)\n  );\n} /** minus line height minus top+bottom padding -- tradeoff is that one line is essential */\n@media (min-width: 768px) {\n.cardContent_F1ziB {\n    transform: translateY(calc(100% - 1.5rem - 10px))\n}\n}\n@media (min-width: 992px) {\n.cardContent_F1ziB {\n    transform: translateY(calc(100% - 1.5rem - 20px))\n}\n}\n.cardContent_F1ziB.hovered_D2LKN {\n  transform: translateY(0);\n}\n.additionalContent_9NT\\+T {\n  opacity: 0;\n  transition: opacity 0.3s ease;\n}\n.cardContent_F1ziB.hovered_D2LKN .additionalContent_9NT\\+T {\n  opacity: 1;\n}\n.upChevron_AxUJp {\n  transition: opacity 0.3s ease;\n}\n.chevronFaded_2zIfN {\n  opacity: 0;\n}\n.subtleShadow_ZCeXS {\n  box-shadow: inset 0 10px 20px -5px rgba(255, 255, 255, 0.8), 0 20px 25px -5px rgba(0, 0, 0, 0.1);\n  background-image: linear-gradient(\n    to bottom,\n    rgba(255, 255, 255, 1) 0%,\n    rgba(255, 255, 255, 0) 100%\n  );\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardContent": "cardContent_F1ziB",
	"hovered": "hovered_D2LKN",
	"additionalContent": "additionalContent_9NT+T",
	"upChevron": "upChevron_AxUJp",
	"chevronFaded": "chevronFaded_2zIfN",
	"subtleShadow": "subtleShadow_ZCeXS"
};
module.exports = ___CSS_LOADER_EXPORT___;
