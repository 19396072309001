
  import IconBase from './IconBase.vue';

  export default {
    name: 'IconAngle',
    components: { IconBase },
    props: {
      type: {
        type: String,
        default: 'up'
      },
      color: {
        type: String,
        default: 'currentColor'
      },
    }
  };
