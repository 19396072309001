// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".trustPilot_KXidt{padding-left:15px;color:#909090}.trustPilot_KXidt h4{font-size:20px;font-weight:bold;margin-top:11px;margin-bottom:11px}.darkLogo_PesNx,.lightLogo_316S3{width:100%}.rating_rKQGC{width:80%;margin:10px 0 0}@media(min-width: 768px){.rating_rKQGC{margin:10px 0}}.dark_XK\\+dx .lightLogo_316S3{display:none}.light_gFgLw{color:#fff}.light_gFgLw .darkLogo_PesNx{display:none}.light_gFgLw div a{color:#41a5dd}@media (min-width: 992px){.trustPilot_KXidt div{width:200px !important}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"trustPilot": "trustPilot_KXidt",
	"darkLogo": "darkLogo_PesNx",
	"lightLogo": "lightLogo_316S3",
	"rating": "rating_rKQGC",
	"dark": "dark_XK+dx",
	"light": "light_gFgLw"
};
module.exports = ___CSS_LOADER_EXPORT___;
