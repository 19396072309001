// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.trustPilotContainer_IjlZx {\n  display: grid;\n  max-width: 350px;\n  cursor: pointer;\n  border-radius: 6px;\n  --tw-bg-opacity: 1;\n  background-color: rgba(255, 255, 255, 1);\n  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));\n  padding: 8px;\n  grid-template-columns: repeat(3, 1fr)\n}\n.trustpilotContainerStacked_lgiqm {\n  display: grid;\n  grid-column-gap: 16px;\n  -moz-column-gap: 16px;\n       column-gap: 16px;\n  grid-row-gap: 8px;\n  row-gap: 8px;\n  grid-template-columns: repeat(2, 1fr)\n}\n.stackedTrustpilotRating_vHNpU {\n  max-height: 27px;\n  grid-column: 1 / -1\n}\n", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"trustPilotContainer": "trustPilotContainer_IjlZx",
	"trustpilotContainerStacked": "trustpilotContainerStacked_lgiqm",
	"stackedTrustpilotRating": "stackedTrustpilotRating_vHNpU"
};
module.exports = ___CSS_LOADER_EXPORT___;
